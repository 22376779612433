import { useState } from "react";
import { Link } from "react-router-dom";
import { Text, UnstyledButton, Group, useMantineTheme, Box, Collapse } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { WebRoutes } from "../../constants";
import { useLocation } from "react-router-dom";

function LinksGroup({ icon, label, links, pathname }) {
	const hasLinks = Array.isArray(links);
	const [opened, setOpened] = useState(links.find((x) => x.link === pathname) !== undefined);
	const items = (hasLinks ? links : []).map((link) => (
		<Text
			component="a"
			sx={(theme) => ({
				fontStyle: "italic",
				display: "block",
				textDecoration: "none",
				paddingLeft: 31,
				paddingTop: 8,
				paddingBottom: 8,
				paddingRight: 15,
				marginLeft: 23,
				fontSize: theme.fontSizes.sm,
				color: pathname === link.link ? "white" : "#9f9f9e",
				borderLeft: `1px solid #716d6d`,
				backgroundColor: pathname === link.link ? theme.fn.darken("#fffaf4", 0.2) : "#fffaf4",

				"&:hover": {
					backgroundColor: theme.fn.darken("#fffaf4", 0.2),
					color: "white",
				},
			})}
			href={link.link}
			key={link.label}
		>
			{link.label}
		</Text>
	));

	return (
		<>
			<UnstyledButton
				component="a"
				sx={(theme) => ({
					display: "block",
					width: "100%",
					padding: theme.spacing.xs,
					borderRadius: theme.radius.sm,

					"&:hover": {
						backgroundColor: theme.fn.darken("#fffaf4", 0.2),
					},
				})}
				onClick={() => setOpened((o) => !o)}
			>
				<Group spacing={0} position="apart">
					<Text size="sm" weight="bold" color="#9f9f9e">
						{label}
					</Text>
					{hasLinks && (
						<IconChevronRight
							size={14}
							stroke={2}
							style={{
								color: "#9f9f9e",
								transition: "transform 200ms ease",
								transform: opened ? `rotate(90deg)` : "none",
							}}
						/>
					)}
				</Group>
			</UnstyledButton>
			{hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
		</>
	);
}

/// <summary>
/// Author: KrisuKodes
/// </summary>
const LinkItem = ({ icon, label, link, active = false, toggleDrawer }) => (
	<Link to={link}>
		<UnstyledButton
			sx={(theme) => ({
				display: "block",
				width: "100%",
				padding: theme.spacing.xs,
				borderRadius: theme.radius.sm,
				backgroundColor: active ? theme.fn.darken("#fffaf4", 0.2) : "#fffaf4",

				"&:hover": {
					backgroundColor: theme.fn.darken("#fffaf4", 0.2),
				},
			})}
			onClick={() => toggleDrawer(false)}
		>
			<Text size="sm" weight="bold" color={active ? "white" : "#9f9f9e"}>
				{label}
			</Text>
		</UnstyledButton>
	</Link>
);

const data = [
	{
		label: "GTLF",
		link: WebRoutes.GTLF,
	},
	{
		label: "Open Call",
		link: WebRoutes.OPEN_CALL,
	},
	{
		label: "Banners",
		link: WebRoutes.BANNERS,
	},
	{
		label: "The Festival",
		links: [
			{
				label: "Muara",
				link: WebRoutes.MUARA,
			},
			{ label: "Muara Writing Prize", link: WebRoutes.MUARA_WRITING_PRIZE },
		],
	},
	{
		label: "Past Festivals",
		link: WebRoutes.PAST_FESTIVALS,
	},
	{
		label: "Media",
		links: [
			{
				label: "Press Centre",
				link: WebRoutes.PRESS_CENTRE,
			},
			{ label: "In The News", link: WebRoutes.IN_THE_NEWS },
		],
	},
	{
		label: "Partners",
		links: [
			{
				label: "Partners",
				link: WebRoutes.PARTNERS,
			},
			{
				label: "Partner Categories",
				link: WebRoutes.PARTNER_CATEGORIES,
			},
		],
	},
	{
		label: "Settings",
		links: [
			{
				label: "Locations",
				link: WebRoutes.LOCATIONS,
			},
		],
	},
];

/// <summary>
/// Author: KrisuKodes
/// </summary>
export default function Links({ toggleDrawer = () => {} }) {
	const { pathname } = useLocation();
	const theme = useMantineTheme();
	const links = data.map((link) => {
		if (link?.links !== undefined) {
			return <LinksGroup pathname={pathname} key={link.label} {...link} />;
		}

		return <LinkItem toggleDrawer={toggleDrawer} active={pathname === link.link} key={link.label} link={link.link} label={link.label} />;
	});

	return (
		<Box
			sx={{
				paddingTop: theme.spacing.sm,
				marginTop: theme.spacing.xs,
				borderTop: `1px solid ${theme.colors.gray[6]}`,
			}}
		>
			{links}
		</Box>
	);
}
