import { Button, createStyles, Drawer, LoadingOverlay, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";

import supabase from "../../utils/Supabase";

const useStyles = createStyles((theme) => ({
	root: {
		backgroundColor: theme.fn.lighten("#2e3b61", 0.1),

		"&:hover": {
			backgroundColor: theme.fn.darken("#2e3b61", 0),
		},

		"&:disabled": {
			backgroundColor: "#2e3b61",
			color: "white",
		},
	},
	menus_form: {
		height: "100%",
		overflow: "auto",
		paddingRight: "1em",
	},
	container: {
		height: "100%",
		width: "100%",
		overflow: "auto",
	},
}));

export default function CreateOrUpdateDrawer({ opened, onClose, locationDetail, successCallback }) {
	const { classes } = useStyles();
	const matches = useMediaQuery("(max-width: 1023px)");

	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			name: "",
		},
		validate: {
			name: (value) => value === "" && "Name is required.",
		},
	});

	useEffect(() => {
		if (!!locationDetail) {
			form.setValues(locationDetail);
		} else {
			form.reset();
		}
	}, [locationDetail]);

	const formHandler = async (values) => {
		try {
			setIsLoading(true);

			let response;

			if (!!locationDetail) {
				response = await supabase.from("event-buildings").update({ name: values.name }).eq("id", locationDetail.id);
			} else {
				response = await supabase.from("event-buildings").insert({
					name: values.name,
				});
			}

			if (response.error !== null) {
				throw response.error.message;
			}

			onCloseHandler();
			showNotification({
				title: "Success!",
				message: `Successfully ${!locationDetail ? "created" : "edited"} "${values?.name}".`,
				color: "green",
			});
			successCallback();
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err,
				color: "red",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onCloseHandler = () => {
		form.reset();
		onClose();
	};

	return (
		<Drawer opened={opened} onClose={onCloseHandler} title={!locationDetail ? "Create Location" : `Edit - ${locationDetail.name}`} padding="xl" position="right" size={matches ? "100%" : "35vw"} closeOnClickOutside={false}>
			<form className={classes.menus_form} onSubmit={form.onSubmit(formHandler)} onReset={form.onReset}>
				<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
				<Stack mt={10} justify="space-between">
					<TextInput withAsterisk label={"Name"} placeholder={"Name"} {...form.getInputProps(`name`)} />
				</Stack>
				<Button type="submit" mt={25} fullWidth classNames={{ root: classes.root }}>
					Save
				</Button>
			</form>
		</Drawer>
	);
}
