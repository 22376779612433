import { AppShell, Text, Divider, Group } from "@mantine/core";

import NavBar from "../../navbar";

/// <summary>
/// Author: KrisuKodes
/// </summary>
export default function DesktopLayout({ title, createBtn, backBtn, hasDivider = true, children }) {
	return (
		<AppShell
			padding="sm"
			navbar={<NavBar />}
			styles={() => ({
				main: { backgroundColor: "white", position: "relative" },
			})}
		>
			<Group>
				{backBtn}
				<Text size="xl">{title}</Text>
				{createBtn}
			</Group>
			{hasDivider ? <Divider mt={6} mb={10} /> : <div style={{ marginTop: 5, marginBottom: 10 }} />}
			{children}
		</AppShell>
	);
}
