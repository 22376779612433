import {
    ActionIcon,
    Box,
    Button,
    Card,
    createStyles,
    Divider,
    Grid,
    Group,
    LoadingOverlay,
    Modal,
    ScrollArea,
    Select,
    Stack,
    Table,
    Text,
    TextInput,
} from '@mantine/core';
import { useMediaQuery, useToggle } from '@mantine/hooks';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useEditor } from '@tiptap/react';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';

import { WebRoutes } from '../../../../../constants';
import supabase from '../../../../../utils/Supabase';
import TableEmptyState from '../../../../../components/common/TableEmptyState';
import dayjs from 'dayjs';
import { statusHandler } from '../../../../../utils/Utils';

const useStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.fn.lighten('#2e3b61', 0.1),

        '&:hover': {
            backgroundColor: theme.fn.darken('#2e3b61', 0),
        },

        '&:disabled': {
            backgroundColor: '#2e3b61',
            color: 'white',
        },
    },
    container: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
    item: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        cursor: 'pointer',
    },
    dragHandle: {
        ...theme.fn.focusStyles(),
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    },
    itemDragging: {
        boxShadow: theme.shadows.sm,
    },
}));

/// <summary>
/// Author: KurisuKodo
/// </summary>
function ProgrammeForm({ details }) {
    const { classes, cx } = useStyles();
    const matches = useMediaQuery('(max-width: 1023px)');
    const navigate = useNavigate();

    const [festivalProgrammes, setFesitvalProgrammes] = useState([]);
    const [isLoading, toggleLoading] = useToggle();
    const [isVisible, toggleVisible] = useToggle();

    const [editSection, setEditSection] = useState(null);

    const [daysOptions, setDaysOptions] = useState([]);
    const [buildingOptions, setBuildingOptions] = useState([]);

    useEffect(() => {
        getFesitvalProgrammes();
        getDayOptions();
        getBuildingOptions();
    }, []);

    useEffect(() => {
        if (editSection !== null) {
            toggleVisible();
        }
    }, [editSection]);

    const getFesitvalProgrammes = async () => {
        toggleLoading();
        try {
            const { data, error } = await supabase
                .from('festival-programmes')
                .select(
                    `*, programme-days (
                    date
                ), event-buildings (
                    name
                )`
                )
                .eq('gtlf_id', details?.id);

            if (error !== null) {
                throw error.message;
            }

            setFesitvalProgrammes(data);
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    const getDayOptions = async () => {
        toggleLoading();
        try {
            const { data, error } = await supabase
                .from('programme-days')
                .select('*')
                .eq('gtlf_id', details?.id);

            if (error !== null) {
                throw error.message;
            }

            let options = data?.map((item) => ({
                label: dayjs(item.date).format('DD MMMM YYYY, dddd'),
                value: item.id,
            }));

            setDaysOptions(options);
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    const getBuildingOptions = async () => {
        toggleLoading();
        try {
            const { data, error } = await supabase.from('event-buildings').select('*');

            if (error !== null) {
                throw error.message;
            }

            let options = data?.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            console.log(options);

            setBuildingOptions(options);
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    const editHandler = (value) => {
        navigate(
            WebRoutes.GTLF_DETAILS.replace(':id', value?.id) +
                `?year=${value?.year}&title=${value?.title}`
        );
    };

    const editSectionHandler = (item) => {
        setEditSection(item);
    };

    const deleteSection = async (item) => {
        toggleLoading();
        try {
            const { error } = await supabase.from('festival-programmes').delete().eq('id', item.id);

            if (error !== null) {
                throw error.message;
            }

            showNotification({
                title: 'Success!',
                message: `Successfully deleted ${item.title}`,
                color: 'green',
            });

            getFesitvalProgrammes();
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    const items = festivalProgrammes?.map((value, index) => (
        <tr className={cx(classes.item)} onClick={() => editHandler(value)}>
            <td>{dayjs(value?.['programme-days']?.date).format('DD MMMM YYYY, dddd')}</td>
            <td>{value?.title}</td>
            <td>{value?.['event-buildings']?.name}</td>
            <td>
                <Group spacing={5}>
                    <ActionIcon
                        color="orange"
                        size="lg"
                        variant="filled"
                        onClick={() => editSectionHandler({ ...value, index })}>
                        <IconPencil size="16" />
                    </ActionIcon>
                    <ActionIcon
                        color="red"
                        size="lg"
                        variant="filled"
                        onClick={() => {
                            statusHandler({
                                id: 'deleteSection',
                                name: '',
                                onConfirm: () => deleteSection(value),
                            });
                        }}>
                        <IconTrash size="16" />
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    ));

    return (
        <Stack
            spacing={0}
            style={{ height: matches ? '100%' : 'auto', maxHeight: matches ? '100%' : '90%' }}
            justify="space-between">
            <Group mt={20}>
                <Button color="indigo" radius="md" onClick={toggleVisible}>
                    Add Festival Programme
                </Button>
            </Group>
            <Stack spacing={0} style={{ height: '95%' }}>
                <ScrollArea mt={10}>
                    <Card p="xs" withBorder>
                        <LoadingOverlay visible={isLoading} overlayBlur={0.5} />
                        <Table highlightOnHover style={{ borderRadius: 10 }}>
                            <thead>
                                <tr>
                                    <th>Festival Date</th>
                                    <th>Title</th>
                                    <th>Location</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {festivalProgrammes.length > 0 ? (
                                    items
                                ) : (
                                    <TableEmptyState colSpan={5} />
                                )}
                            </tbody>
                        </Table>
                    </Card>
                </ScrollArea>
            </Stack>
            <SectionModal
                isVisible={isVisible}
                toggleVisible={toggleVisible}
                buildingOptions={buildingOptions}
                daysOptions={daysOptions}
                editSection={editSection}
                details={details}
                getFesitvalProgrammes={getFesitvalProgrammes}
            />
        </Stack>
    );
}

const SectionModal = ({
    isVisible,
    toggleVisible,
    buildingOptions,
    daysOptions,
    editSection,
    details,
    getFesitvalProgrammes,
}) => {
    const [title, setTitle] = useState(null);
    const [speakers, setSpeakers] = useState(null);
    const [moderator, setModerator] = useState(null);
    const [host, setHost] = useState(null);
    const [director, setDirector] = useState(null);
    const [runtime, setRuntime] = useState(null);
    const [timeslot, setTimeslot] = useState(null);
    const [link, setLink] = useState(null);

    const [dayId, setDayId] = useState(null);
    const [locationId, setLocationId] = useState(null);

    const editor = useEditor({
        extensions: [
            StarterKit,
            TextStyle,
            Color,
            Link,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
    });

    const sectionHandler = async () => {
        let programmeObj = {
            gtlf_id: details.id,
            programme_day_id: dayId,
            location_id: locationId,
            index: 1,
            title: title,
            desc: editor.getHTML(),
            speakers: speakers,
            moderator: moderator,
            host: host,
            timeslot: timeslot,
            director: director,
            runtime: runtime,
            link: link,
        };

        let response;

        if (editSection !== null) {
            response = await supabase
                .from('festival-programmes')
                .update(programmeObj)
                .eq('id', editSection.id);

            if (response.error !== null) {
                throw response.error.message;
            }
            showNotification({
                title: 'Success!',
                message: `Successfully edited festival programme.`,
                color: 'green',
            });
        } else {
            response = await supabase.from('festival-programmes').insert(programmeObj);

            if (response.error !== null) {
                throw response.error.message;
            }
            showNotification({
                title: 'Success!',
                message: `Successfully added festival programme.`,
                color: 'green',
            });
        }

        setTitle(null);
        setSpeakers(null);
        setModerator(null);
        setHost(null);
        setTimeslot(null);
        setLink(null);
        setDirector(null);
        setRuntime(null);
        setDayId(null);
        setLocationId(null);
        editor.commands.setContent(null);

        getFesitvalProgrammes();
        toggleVisible();
    };

    useEffect(() => {
        if (isVisible && editSection) {
            editor.commands.setContent(editSection?.desc);
            setTitle(editSection.title);
            setSpeakers(editSection.speakers);
            setModerator(editSection.moderator);
            setHost(editSection.host);
            setTimeslot(editSection.timeslot);
            setLink(editSection.link);
            setDirector(editSection.director);
            setRuntime(editSection.runtime);
            setDayId(editSection.programme_day_id);
            setLocationId(editSection.location_id);
        }
    }, [isVisible, editSection]);

    return (
        <Modal
            opened={isVisible}
            onClose={() => {
                toggleVisible();
                setTitle(null);
                setSpeakers(null);
                setModerator(null);
                setHost(null);
                setTimeslot(null);
                setLink(null);
                setDirector(null);
                setRuntime(null);
                setDayId(null);
                setLocationId(null);
                editor.commands.setContent(null);
            }}
            title={`${editSection ? 'Edit' : 'Add'} Programme Day`}
            size="70%">
            <Grid>
                <Grid.Col span={6}>
                    <Select
                        label="Programme Day"
                        placeholder="Pick a day..."
                        data={daysOptions}
                        onChange={setDayId}
                        value={dayId}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <Select
                        label="Programme Location"
                        placeholder="Pick a location..."
                        data={buildingOptions}
                        onChange={setLocationId}
                        value={locationId}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label="Title"
                        onChange={(e) => setTitle(e.currentTarget.value)}
                        value={title}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label="Speakers"
                        onChange={(e) => setSpeakers(e.currentTarget.value)}
                        value={speakers}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="Moderator"
                        onChange={(e) => setModerator(e.currentTarget.value)}
                        value={moderator}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="Host"
                        onChange={(e) => setHost(e.currentTarget.value)}
                        value={host}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="Director"
                        onChange={(e) => setDirector(e.currentTarget.value)}
                        value={director}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="Runtime"
                        onChange={(e) => setRuntime(e.currentTarget.value)}
                        value={runtime}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="Link"
                        onChange={(e) => setLink(e.currentTarget.value)}
                        value={link}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="Timeslot"
                        onChange={(e) => setTimeslot(e.currentTarget.value)}
                        value={timeslot}
                    />
                </Grid.Col>
            </Grid>

            <Text fz="sm" mt={20}>
                Description (optional)
            </Text>
            <RichTextEditor editor={editor} style={{ minHeight: 400 }}>
                <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.ColorPicker
                            colors={[
                                '#25262b',
                                '#868e96',
                                '#fa5252',
                                '#e64980',
                                '#be4bdb',
                                '#7950f2',
                                '#4c6ef5',
                                '#228be6',
                                '#15aabf',
                                '#12b886',
                                '#40c057',
                                '#82c91e',
                                '#fab005',
                                '#fd7e14',
                            ]}
                        />
                        <RichTextEditor.Link />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                        <RichTextEditor.H5 />
                        <RichTextEditor.H6 />
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Hr />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignRight />
                        <RichTextEditor.AlignJustify />
                    </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content />
            </RichTextEditor>
            <Divider my={20} />
            <Group position="right">
                <Box w={200}>
                    <Button onClick={sectionHandler} color="teal" variant="filled" fullWidth>
                        Save
                    </Button>
                </Box>
            </Group>
        </Modal>
    );
};

export default ProgrammeForm;
