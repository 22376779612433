import React, { useEffect } from 'react';
import {
    Grid,
    LoadingOverlay,
    ScrollArea,
    Stack,
    Switch,
    Text,
    Textarea,
    TextInput,
    useMantineTheme,
} from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { IconCheck, IconX } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { DateTimePicker, YearPickerInput } from '@mantine/dates';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import supabase from '../../../../../utils/Supabase';

import { ImageUpload, FormFooter } from '../../../../../components/common';

function DetailsForm({ details }) {
    const theme = useMantineTheme();

    const [isLoading, toggleLoading] = useToggle();

    const form = useForm({
        initialValues: {
            id: null,
            year: dayjs().toDate(),
            title: '',
            alias: '',
            desc: '',
            theme: '',
            image: null,
            status: false,
            start_date: null,
            end_date: null,
        },
        validate: {
            title: (value) => value === '' && 'Title is required.',
            alias: (value) => value === '' && 'Alias is required.',
            desc: (value) => value === '' && 'Description is required.',
            theme: (value) => value === '' && 'Theme is required.',
            start_date: (value) => value === null && 'Start Date is required.',
            end_date: (value) => value === null && 'End Date is required.',
            image: (value) => (value === null || value.length === 0) && 'GTLF Cover is required.',
        },
    });

    useEffect(() => {
        if (details) {
            form.setValues(details);
        }
    }, [details]);

    const formHandler = async (values) => {
        try {
            toggleLoading();

            let year = dayjs(values.year).format('YYYY');
            console.log(values);
            let imgPath = `${year}/cover/cover.png`;
            let { file } = values.image[0];

            if (file !== undefined) {
                await supabase.storage.from('gtlf').remove([`${year}/cover/cover.png`]);

                const { data, error } = await supabase.storage.from('gtlf').upload(imgPath, file, {
                    cacheControl: '3600',
                    upsert: false,
                });

                if (error !== null) {
                    throw error.message;
                }
            }

            let response = await supabase
                .from('gtlf')
                .update({
                    title: values.title,
                    year: year,
                    alias: values.alias,
                    theme: values.theme,
                    status: values.status,
                    start_date: values.start_date,
                    end_date: values.end_date,
                    desc: values.desc,
                })
                .eq('id', values?.id);

            if (response.error !== null) {
                throw response.error.message;
            }

            showNotification({
                title: 'Success!',
                message: `Successfully edited.`,
                color: 'green',
            });
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err.toString(),
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    return (
        <Stack style={{ marginBottom: 70, marginTop: 10 }}>
            <form onSubmit={form.onSubmit(formHandler)} onReset={form.reset}>
                <ScrollArea type="never">
                    <LoadingOverlay visible={isLoading} overlayBlur={0.5} />
                    <Grid>
                        <Grid.Col lg={12}>
                            <ImageUpload
                                maxNumber={1}
                                title={'GTLF Cover'}
                                withAsterisk
                                imgWidth={250}
                                {...form.getInputProps(`image`)}
                            />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                            <YearPickerInput
                                withAsterisk
                                label="GTLF Year"
                                placeholder="Pick Year"
                                mx="auto"
                                {...form.getInputProps('year')}
                            />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                            <TextInput
                                label="Alias"
                                withAsterisk
                                placeholder="e.g 12th Edition"
                                {...form.getInputProps('alias')}
                            />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                            <DateTimePicker
                                withAsterisk
                                label="Start Date & Time"
                                placeholder="Pick.."
                                maw={400}
                                mx="auto"
                                maxDate={form.values.end_date}
                                {...form.getInputProps('start_date')}
                            />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                            <DateTimePicker
                                withAsterisk
                                label="End Date & Time"
                                placeholder="Pick.."
                                maw={400}
                                mx="auto"
                                minDate={form.values.start_date}
                                {...form.getInputProps('end_date')}
                            />
                        </Grid.Col>
                        <Grid.Col lg={12}>
                            <TextInput
                                label="Title"
                                withAsterisk
                                {...form.getInputProps('title')}
                            />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                            <Textarea
                                label="Theme"
                                placeholder="Explain what your theme this year explores"
                                minRows={8}
                                withAsterisk
                                {...form.getInputProps('theme')}
                            />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                            <Textarea
                                label="Description"
                                placeholder="Explain more about what the theme means"
                                minRows={8}
                                withAsterisk
                                {...form.getInputProps('desc')}
                            />
                        </Grid.Col>
                        <Grid.Col lg={12}>
                            <Stack spacing={0}>
                                <Text>Set as active GTLF?</Text>
                                <Switch
                                    {...form.getInputProps('status', { type: 'checkbox' })}
                                    color="teal"
                                    size="lg"
                                    thumbIcon={
                                        form.values.status ? (
                                            <IconCheck
                                                size="0.8rem"
                                                color={theme.colors.teal[theme.fn.primaryShade()]}
                                                stroke={3}
                                            />
                                        ) : (
                                            <IconX
                                                size="0.8rem"
                                                color={theme.colors.red[theme.fn.primaryShade()]}
                                                stroke={3}
                                            />
                                        )
                                    }
                                />
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </ScrollArea>
                <FormFooter text="Save Details" />
            </form>
        </Stack>
    );
}

export default DetailsForm;
