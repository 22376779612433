import { Drawer, FileInput, Grid, LoadingOverlay, NumberInput, Select, Stack, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import SubmitButton from "../../components/common/SubmitButton";
import supabase from "../../utils/Supabase";
import dayjs from "dayjs";
import { YearPickerInput } from "@mantine/dates";

export default ({ opened, onClose, categoryDetail, successCallback }) => {
	const matches = useMediaQuery("(max-width: 1023px)");

	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			title: "",
		},
		validate: {
			title: (value) => value === "" && "Title is required.",
		},
	});

	useEffect(() => {
		if (!!categoryDetail) {
			form.setValues({
				title: categoryDetail?.title,
			});
		} else {
			form.reset();
		}
	}, [categoryDetail]);

	const formHandler = async (values) => {
		try {
			setIsLoading(true);

			let response;

			if (!!categoryDetail) {
				response = await supabase.from("partner-category").update(values).eq("id", categoryDetail.id);
			} else {
				response = await supabase.from("partner-category").insert(values);
			}

			if (response.error !== null) {
				throw response.error.message;
			}

			onCloseHandler();
			showNotification({
				title: "Success!",
				message: `Successfully ${!categoryDetail ? "created" : "edited"} category.`,
				color: "green",
			});
			successCallback();
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err.toString(),
				color: "red",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onCloseHandler = () => {
		form.reset();
		onClose();
	};

	return (
		<Drawer opened={opened} onClose={onCloseHandler} title={categoryDetail ? "Edit Partner Category" : "Create Partner Category"} padding="xl" position="right" size={matches ? "100%" : "35vw"} closeOnClickOutside={false}>
			<form onSubmit={form.onSubmit(formHandler)} onReset={form.onReset}>
				<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
				<Stack mt={10} mb={200} justify="space-between">
					<Grid>
						<Grid.Col span={12}>
							<TextInput label="Title" placeholder="Title" withAsterisk {...form.getInputProps("title")} />
						</Grid.Col>
					</Grid>
					<SubmitButton />
				</Stack>
			</form>
		</Drawer>
	);
};
