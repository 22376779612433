import { Drawer, FileInput, Grid, LoadingOverlay, NumberInput, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { ImageUpload } from "../../components/common";
import SubmitButton from "../../components/common/SubmitButton";
import { StatusData, TranslationData } from "../../constants";
import supabase from "../../utils/Supabase";
import dayjs from "dayjs";

export default ({ opened, onClose, pastFestivalDetail, successCallback }) => {
	const matches = useMediaQuery("(max-width: 1023px)");

	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			image: "",
			file: "",
		},
		validate: {
			image: (value) => value === "" && "Image is required.",
			file: (value) => value === "" && "File is required.",
		},
	});

	useEffect(() => {
		if (!!pastFestivalDetail) {
			console.log(pastFestivalDetail);
			form.setValues({
				image: pastFestivalDetail?.title,
				link: pastFestivalDetail?.link,
			});
		} else {
			form.reset();
		}
	}, [pastFestivalDetail]);

	const formHandler = async (values) => {
		try {
			setIsLoading(true);

			let response;

			if (!!pastFestivalDetail) {
				response = await supabase.from("past-festivals").update(values).eq("id", pastFestivalDetail.id);
			} else {
				let file = values.file;

				const { data: fileData, error: fileError } = await supabase.storage.from("past-festivals").upload(file.name, file, {
					cacheControl: "3600",
					upsert: false,
				});

				if (fileError !== null) {
					throw fileError.message;
				}

				let image = values.image;

				const { data: imageData, error: imageError } = await supabase.storage.from("past-festivals").upload(image.name, image, {
					cacheControl: "3600",
					upsert: false,
				});

				if (imageError !== null) {
					throw imageError.message;
				}

				response = await supabase.from("past-festivals").insert({
					image: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/past-festivals/${imageData.path}`,
					link: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/past-festivals/${fileData.path}`,
				});
			}

			if (response.error !== null) {
				throw response.error.message;
			}

			onCloseHandler();
			showNotification({
				title: "Success!",
				message: `Successfully ${!pastFestivalDetail ? "created" : "edited"}.`,
				color: "green",
			});
			successCallback();
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err.toString(),
				color: "red",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onCloseHandler = () => {
		form.reset();
		onClose();
	};

	return (
		<Drawer opened={opened} onClose={onCloseHandler} title={pastFestivalDetail ? "Edit Past Festival" : "Create Past Festival"} padding="xl" position="right" size={matches ? "100%" : "35vw"} closeOnClickOutside={false}>
			<form onSubmit={form.onSubmit(formHandler)} onReset={form.onReset}>
				<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
				<Stack mt={10} justify="space-between">
					<Grid>
						<Grid.Col span={12}>
							<FileInput label="Image" placeholder="Image" withAsterisk {...form.getInputProps("image")} />
						</Grid.Col>
						<Grid.Col span={12}>
							<FileInput label="File" placeholder="File" withAsterisk {...form.getInputProps("file")} />
						</Grid.Col>
					</Grid>
					<SubmitButton />
				</Stack>
			</form>
		</Drawer>
	);
};
