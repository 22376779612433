import supabase from "../../utils/Supabase";
import { Anchor, Button, Card, Center, FocusTrap, Image, PasswordInput, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery, useToggle } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";

import { useNavigate } from "react-router-dom";
import { WebRoutes } from "../../constants/WebRoutes";

/// <summary>
/// Author: KrisuKodes
/// </summary>
export default function Login() {
	const navigate = useNavigate();
	const matches = useMediaQuery("(max-width: 1023px)");

	const [isLoading, toggleLoading] = useToggle();

	const form = useForm({
		initialValues: {
			email: "",
			password: "",
		},
		validate: {
			email: (value) => value === "" && "Email is required.",
			password: (value) => value === "" && "Password is required. ",
		},
	});

	/// <summary>
	/// Author: KrisuKodes
	/// </summary>
	const loginHandler = async (values) => {
		toggleLoading();

		try {
			let response;

			response = await supabase.auth.signInWithPassword(values);

			let { data, error: signInError } = response;

			if (signInError !== null) {
				throw signInError.message;
			}

			let cleanedData = {
				id: data?.user?.id,
				email: data?.user?.email,
				token: data?.session?.access_token,
				alias: values.alias,
			};

			localStorage.setItem("gtlfUserData", JSON.stringify(cleanedData));

			navigate(WebRoutes.GTLF, {
				replace: true,
			});
		} catch (error) {
			showNotification({
				title: "Oops",
				message: error,
				color: "red",
			});
		} finally {
			toggleLoading();
		}
	};

	return (
		<Center style={{ width: "100vw", height: "100vh" }}>
			<Stack>
				<Center mb={5}>
					<Text size="xl" color="black" weight="bold">
						GTLF Admin
					</Text>
				</Center>

				<FocusTrap>
					<Card style={{ width: matches ? "85vw" : "30vw", maxWidth: "360px" }} p="lg" radius="md" withBorder>
						<form onSubmit={form.onSubmit(loginHandler)}>
							<TextInput withAsterisk label="Email" {...form.getInputProps("email")} />
							<PasswordInput withAsterisk mt={10} label="Password" {...form.getInputProps("password")} />

							<Button
								type="submit"
								mt={25}
								fullWidth
								styles={(theme) => ({
									root: {
										backgroundColor: theme.fn.lighten("#2e3b61", 0.1),

										"&:hover": {
											backgroundColor: theme.fn.darken("#2e3b61", 0),
										},

										"&:disabled": {
											backgroundColor: "#2e3b61",
											color: "white",
										},
									},
								})}
								loading={isLoading}
							>
								Login
							</Button>
						</form>
						<Center mt={15}>
							<Anchor component="button" type="button">
								<Text size="sm">Forgot your password?</Text>
							</Anchor>
						</Center>
					</Card>
				</FocusTrap>
			</Stack>
		</Center>
	);
}
