import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data === null || data === '' || data === undefined || data == NaN;
}

/// <summary>
/// Author: BenCoDeS
/// </summary>
export function isObjectEmpty(object) {
    return Object.keys(object).length === 0;
}

/// <summary>
/// Author: BenCoDeS
/// </summary>
export function constructQueryString(url, filterObj) {
    if (typeof url === 'string' && url !== '' && filterObj) {
        url +=
            (url.includes('?') ? '&' : '?') +
            Object.keys(filterObj)
                .filter((key) => !stringIsNullOrEmpty(filterObj[key]))
                .map((key) => key + '=' + encodeURIComponent(filterObj[key]))
                .join('&');
    }

    return url;
}

/// <summary>
/// Author: BenCoDeS
/// </summary>
export function statusHandler({ id, name, onConfirm, zIndex = 300 }) {
    openConfirmModal({
        title: 'Hold on!',
        centered: true,
        children: (
            <Text>
                Are you sure you would like to{' '}
                <Text color="red" weight={500} component="span">
                    remove
                </Text>{' '}
                {`"${name}"`}?
            </Text>
        ),
        labels: { confirm: 'Remove', cancel: 'No' },
        confirmProps: { color: 'red' },
        zIndex: zIndex,
        onConfirm: () => onConfirm(id),
    });
}

/// <summary>
/// Author: KrisuKodes
/// </summary>
export const debounce = (func) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, 500);
    };
};
