import { ActionIcon, Button, Card, createStyles, Image, LoadingOverlay, ScrollArea, Stack, Table, Text } from "@mantine/core";
import { useMediaQuery, useToggle } from "@mantine/hooks";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { showNotification } from "@mantine/notifications";
import { statusHandler } from "../../utils/Utils";
import CreateOrUpdateDrawer from "./CreateOrUpdateDrawer";
import TableEmptyState from "../../components/common/TableEmptyState";
import supabase from "../../utils/Supabase";

const useStyles = createStyles((theme) => ({
	root: {
		backgroundColor: theme.fn.lighten("#2e3b61", 0.1),

		"&:hover": {
			backgroundColor: theme.fn.darken("#2e3b61", 0),
		},

		"&:disabled": {
			backgroundColor: "#2e3b61",
			color: "white",
		},
	},
	container: {
		height: "100%",
		width: "100%",
		overflow: "auto",
	},
	item: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		cursor: "pointer",
	},
	dragHandle: {
		...theme.fn.focusStyles(),
		width: 40,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[6],
	},
	itemDragging: {
		boxShadow: theme.shadows.sm,
	},
}));

function PressCentre() {
	const { classes, cx } = useStyles();
	const matches = useMediaQuery("(max-width: 1023px)");

	const [presses, setPresses] = useState([]);

	const [isLoading, toggleLoading] = useToggle();

	const [showDrawer, toggleShowDrawer] = useToggle();
	const [pressDetail, setBannerDetail] = useState(null);

	useEffect(() => {
		getPresses();
	}, []);

	useEffect(() => {
		if (pressDetail !== null) {
			toggleShowDrawer();
		}
	}, [pressDetail]);

	const getPresses = async () => {
		toggleLoading();
		try {
			const { data, error } = await supabase.from("press-centre").select("*").eq("is_deleted", false).order("index", { ascending: false });

			if (error !== null) {
				throw error.message;
			}

			setPresses(data);
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err,
				color: "red",
			});
		} finally {
			toggleLoading();
		}
	};

	const deleteBanner = async (id) => {
		toggleLoading();
		try {
			const { error } = await supabase.from("press-centre").update({ is_deleted: true }).eq("id", id);

			if (error !== null) {
				throw error.message;
			}

			showNotification({
				title: "Success!",
				message: `Successfully deleted.`,
				color: "green",
			});
			getPresses();
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err,
				color: "red",
			});
		} finally {
			toggleLoading();
		}
	};

	const createHandler = () => {
		toggleShowDrawer();
	};

	const closeDrawer = () => {
		toggleShowDrawer();
		setBannerDetail(null);
	};

	const items = presses?.map((value, index) => (
		<tr className={cx(classes.item)} onClick={() => setBannerDetail(value)}>
			<td>{value?.title}</td>
			<td>{value?.date}</td>
			<td>
				<ActionIcon
					color="red"
					variant="light"
					onClick={(e) => {
						e.stopPropagation();
						statusHandler({
							id: value?.id,
							name: "this press",
							onConfirm: deleteBanner,
						});
					}}
				>
					<IconTrash size={16} />
				</ActionIcon>
			</td>
		</tr>
	));

	return (
		<Layout
			title="Press Centre"
			createBtn={
				<Button onClick={createHandler} color="teal" variant="filled" compact leftIcon={<IconPlus size="16" />}>
					New
				</Button>
			}
		>
			<Stack spacing={0} style={{ height: matches ? "100%" : "auto", maxHeight: matches ? "100%" : "90%" }} justify="space-between">
				<Stack spacing={0} style={{ height: "95%" }}>
					<ScrollArea mt={10}>
						<Card p="xs" withBorder>
							<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
							<Table highlightOnHover style={{ borderRadius: 10 }}>
								<thead>
									<tr>
										<th>Title</th>
										<th>Date</th>
										<th style={{ width: "auto" }}></th>
									</tr>
								</thead>
								<tbody>{presses.length > 0 ? items : <TableEmptyState colSpan={5} />}</tbody>
							</Table>
						</Card>
					</ScrollArea>
				</Stack>
			</Stack>
			<CreateOrUpdateDrawer opened={showDrawer} onClose={closeDrawer} successCallback={getPresses} pressDetail={pressDetail} />
		</Layout>
	);
}

export default PressCentre;
