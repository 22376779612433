import { Title, Center, Image } from "@mantine/core";

/// <summary>
/// Author: KrisuKodes
/// </summary>
export default function Brand() {
	return (
		<Center inline px={10} pt={3}>
			<Title order={4} color="#716d6d">
				GTLF Admin
			</Title>
		</Center>
	);
}
