export const WebRoutes = {
    LOGIN: '/login',
    GTLF: '/gtlf',
    GTLF_DETAILS: '/gtlf/details/:id',
    GTLF_CREATE: '/gtlf/create',
    LOCATIONS: '/locations',
    PRESS_CENTRE: '/press-centre',
    IN_THE_NEWS: '/in-the-news',
    PARTNERS: '/partners',
    PARTNER_CATEGORIES: '/partner-categories',
    MUARA: '/muara',
    PAST_FESTIVALS: '/past-festivals',
    BANNERS: '/banners',
    OPEN_CALL: '/open-call',
    MUARA_WRITING_PRIZE: '/muara-writing-prize',
};
