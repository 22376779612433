import {
    ActionIcon,
    Box,
    Button,
    Card,
    Divider,
    Group,
    Image,
    LoadingOverlay,
    Modal,
    Stack,
    Text,
    TextInput,
} from '@mantine/core';
import { useMediaQuery, useToggle } from '@mantine/hooks';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { useEditor } from '@tiptap/react';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import { ImageUpload } from '../../../../../components/common';
import supabase from '../../../../../utils/Supabase';
import { statusHandler } from '../../../../../utils/Utils';
import dayjs from 'dayjs';

function HappeningForm({ details }) {
    const matches = useMediaQuery('(max-width: 1023px)');

    const [isLoading, toggleLoading] = useToggle();
    const [isVisible, toggleVisible] = useToggle();
    const [happenings, setHappenings] = useState([]);

    const [editSection, setEditSection] = useState(null);

    useEffect(() => {
        if (editSection !== null) {
            toggleVisible();
        }
    }, [editSection]);

    useEffect(() => {
        getHappenings();
    }, []);

    const getHappenings = async () => {
        toggleLoading();
        try {
            const { data, error } = await supabase
                .from('happening-simultaneously')
                .select('*')
                .eq('gtlf_id', details?.id);

            if (error !== null) {
                throw error.message;
            }

            setHappenings(data);
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    const editSectionHandler = (item) => {
        setEditSection(item);
    };

    const deleteSection = async (item) => {
        toggleLoading();
        try {
            const url = item.image.split('/').splice(-3);

            const { error: deleteError } = await supabase.storage
                .from('gtlf')
                .remove([url.join('/')]);

            if (deleteError !== null) {
                throw error.message;
            }

            const { error } = await supabase
                .from('happening-simultaneously')
                .delete()
                .eq('id', item.id);

            if (error !== null) {
                throw error.message;
            }

            showNotification({
                title: 'Success!',
                message: `Successfully deleted ${item.name}`,
                color: 'green',
            });

            getHappenings();
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    return (
        <Stack
            spacing={0}
            style={{ height: matches ? '100%' : 'auto', maxHeight: matches ? '100%' : '90%' }}
            justify="space-between">
            <LoadingOverlay visible={isLoading} overlayBlur={0.5} />
            {happenings.map((item, index) => (
                <Card mt={20} shadow="sm" padding="lg" radius="md" withBorder>
                    <Group position="apart" align="start">
                        <div style={{ width: 68 }}></div>
                        <Image
                            width={150}
                            src={item.image.data_url ?? item.image}
                            alt="Image"
                            styles={{ image: { aspectRatio: '1/1.41 !important' } }}
                        />
                        <Group spacing={5}>
                            <ActionIcon
                                color="orange"
                                size="lg"
                                variant="filled"
                                onClick={() => editSectionHandler({ ...item, index })}>
                                <IconPencil size="16" />
                            </ActionIcon>
                            <ActionIcon
                                color="red"
                                size="lg"
                                variant="filled"
                                onClick={() => {
                                    statusHandler({
                                        id: 'deleteSection',
                                        name: '',
                                        onConfirm: () => deleteSection(item),
                                    });
                                }}>
                                <IconTrash size="16" />
                            </ActionIcon>
                        </Group>
                    </Group>
                    <Text mt={5} mb={5} align="center">
                        {item.link}
                    </Text>
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </Card>
            ))}
            <Group position="center" mt={20}>
                <Button color="indigo" radius="md" onClick={toggleVisible}>
                    Add Happening Simultaneously
                </Button>
            </Group>
            <SectionModal
                isVisible={isVisible}
                toggleVisible={toggleVisible}
                setHappenings={setHappenings}
                getHappenings={getHappenings}
                editSection={editSection}
                setEditSection={setEditSection}
                details={details}
            />
        </Stack>
    );
}

const SectionModal = ({
    isVisible,
    toggleVisible,
    getHappenings,
    editSection,
    setEditSection,
    details,
}) => {
    const [image, setImage] = useState(null);
    const [link, setLink] = useState(null);

    const editor = useEditor({
        extensions: [
            StarterKit,
            TextStyle,
            Color,
            Link,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
    });

    const sectionHandler = async () => {
        let happeningObj = {
            link: link,
            desc: editor.getHTML(),
        };

        let datetime = dayjs().valueOf();
        let year = dayjs(details.year).format('YYYY');
        let imgPath = `${year}/happening-simultaneously/${datetime}.png`;
        let { file } = image[0];

        if (file !== undefined) {
            if (editSection !== null) {
                const url = editSection.image.split('/').splice(-3);

                const { error: deleteError } = await supabase.storage
                    .from('gtlf')
                    .remove([url.join('/')]);

                if (deleteError !== null) {
                    throw error.message;
                }
            }

            const { data, error } = await supabase.storage.from('gtlf').upload(imgPath, file, {
                cacheControl: '3600',
                upsert: false,
            });

            if (error !== null) {
                throw error.message;
            }

            happeningObj = {
                ...happeningObj,
                image: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/gtlf/${data.path}`,
            };
        }

        let response;

        if (editSection !== null) {
            response = await supabase
                .from('happening-simultaneously')
                .update(happeningObj)
                .eq('id', editSection.id);

            if (response.error !== null) {
                throw response.error.message;
            }
            showNotification({
                title: 'Success!',
                message: `Successfully edited happening simultaneously.`,
                color: 'green',
            });
        } else {
            happeningObj = {
                ...happeningObj,
                gtlf_id: details.id,
            };

            response = await supabase.from('happening-simultaneously').insert(happeningObj);

            if (response.error !== null) {
                throw response.error.message;
            }
            showNotification({
                title: 'Success!',
                message: `Successfully added happening simultaneously.`,
                color: 'green',
            });
        }

        setImage(null);
        setEditSection(null);
        setLink(null);
        getHappenings();
        toggleVisible();
    };

    useEffect(() => {
        if (isVisible && editSection) {
            editor.commands.setContent(editSection?.content);
            setImage([editSection?.image]);
            setLink(editSection.link);
        }
    }, [isVisible, editSection]);

    return (
        <Modal
            opened={isVisible}
            onClose={() => {
                toggleVisible();
                setEditSection(null);
            }}
            title={`${editSection ? 'Edit' : 'Add'} Happening Simultaneously`}
            size="70%">
            <ImageUpload
                maxNumber={1}
                title={'Image'}
                imgWidth={100}
                aspectRatio={1 / 1.41}
                onChange={setImage}
                value={image}
            />
            <TextInput
                mt={20}
                label="Link (optional)"
                onChange={(e) => setLink(e.currentTarget.value)}
                value={link}
            />
            <Text fz="sm" mt={20}>
                Description (optional)
            </Text>
            <RichTextEditor editor={editor} style={{ minHeight: 400 }}>
                <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.ColorPicker
                            colors={[
                                '#25262b',
                                '#868e96',
                                '#fa5252',
                                '#e64980',
                                '#be4bdb',
                                '#7950f2',
                                '#4c6ef5',
                                '#228be6',
                                '#15aabf',
                                '#12b886',
                                '#40c057',
                                '#82c91e',
                                '#fab005',
                                '#fd7e14',
                            ]}
                        />
                        <RichTextEditor.Link />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                        <RichTextEditor.H5 />
                        <RichTextEditor.H6 />
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Hr />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignRight />
                        <RichTextEditor.AlignJustify />
                    </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content />
            </RichTextEditor>
            <Divider my={20} />
            <Group position="right">
                <Box w={200}>
                    <Button onClick={sectionHandler} color="teal" variant="filled" fullWidth>
                        Save
                    </Button>
                </Box>
            </Group>
        </Modal>
    );
};

export default HappeningForm;
