import {
    Drawer,
    FileInput,
    Grid,
    LoadingOverlay,
    NumberInput,
    ScrollArea,
    Select,
    Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ImageUpload } from '../../components/common';
import SubmitButton from '../../components/common/SubmitButton';
import { StatusData, TranslationData } from '../../constants';
import supabase from '../../utils/Supabase';

export default ({ opened, onClose, partnerDetail, successCallback }) => {
    const matches = useMediaQuery('(max-width: 1023px)');

    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoryLoading, setCategoryLoading] = useState(false);

    const form = useForm({
        initialValues: {
            categoryId: '',
            logo: [],
        },
        validate: {
            categoryId: (value) => value === '' && 'Category is required.',
            logo: (value) => value.length === 0 && !partnerDetail && 'Logo is required.',
        },
    });

    useEffect(() => {
        getCategories();
    }, [opened]);

    useEffect(() => {
        if (!!partnerDetail) {
            form.setValues({
                index: partnerDetail?.index,
                active: partnerDetail?.active,
            });
        } else {
            form.reset();
        }
    }, [partnerDetail]);

    const getCategories = async () => {
        try {
            setCategoryLoading(true);

            const { data, error } = await supabase
                .from('partner-category')
                .select('*')
                .eq('is_deleted', false);

            if (error !== null) {
                throw error.message;
            }

            let cleanedCategories = data.map((item) => ({
                value: item.id,
                label: item.title,
            }));

            setCategories(cleanedCategories);
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            setCategoryLoading(false);
        }
    };

    const formHandler = async (values) => {
        try {
            setIsLoading(true);

            let response;

            if (!!partnerDetail) {
                response = await supabase
                    .from('partners')
                    .update({ category_id: values.categoryId })
                    .eq('id', partnerDetail.id);
            } else {
                let file = values.logo;

                const { data, error } = await supabase.storage
                    .from('partners')
                    .upload(file.name, file, {
                        cacheControl: '3600',
                        upsert: false,
                    });

                if (error !== null) {
                    throw error.message;
                }

                response = await supabase.from('partners').insert({
                    image: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/partners/${data.path}`,
                    category_id: values.categoryId,
                });
            }

            if (response.error !== null) {
                throw response.error.message;
            }

            onCloseHandler();
            showNotification({
                title: 'Success!',
                message: `Successfully ${!partnerDetail ? 'created' : 'edited'}.`,
                color: 'green',
            });
            successCallback();
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err.toString(),
                color: 'red',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onCloseHandler = () => {
        form.reset();
        onClose();
    };

    return (
        <Drawer
            opened={opened}
            onClose={onCloseHandler}
            title={partnerDetail ? 'Edit Partner' : 'Create Partner'}
            padding="xl"
            position="right"
            size={matches ? '100%' : '35vw'}
            closeOnClickOutside={false}>
            <form
                onSubmit={form.onSubmit(formHandler)}
                onReset={form.onReset}
                style={{ height: '50vh' }}>
                <LoadingOverlay visible={isLoading} overlayBlur={0.5} />
                <Stack mt={10} justify="space-between">
                    <Grid>
                        <Grid.Col span={12}>
                            <Select
                                label="Category"
                                placeholder="Category"
                                data={categories}
                                {...form.getInputProps('categoryId')}
                                withAsterisk
                            />
                        </Grid.Col>
                        {!partnerDetail && (
                            <Grid.Col span={12}>
                                <FileInput
                                    label="Logo"
                                    placeholder="Logo"
                                    {...form.getInputProps('logo')}
                                    withAsterisk
                                />
                            </Grid.Col>
                        )}
                    </Grid>
                    <SubmitButton />
                </Stack>
            </form>
        </Drawer>
    );
};
