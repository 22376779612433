export * from "./ApiRoutes";
export * from "./WebRoutes";

export const ApiKey = {
	APPLICATION_JSON: "application/json",
	DATA_KEY: "data",
};

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "MM/DD/YYYY HH:mm A";
export const DATE_TIME_REVERSE_FORMAT = "DD/MM/YYYY HH:mm A";
export const APP_NAME = "GTLF Admin";

export const OrderStatus = {
	RECEIVED: {
		text: "Received",
		color: "gray",
		value: 1,
	},
	PENDING: {
		text: "Pending",
		color: "orange",
		value: 2,
	},
	PREPARATION: {
		text: "Preparation",
		color: "blue",
		value: 3,
	},
	DELIVERY: {
		text: "Delivery",
		color: "green",
		value: 4,
	},
	COMPLETED: {
		text: "Completed",
		color: "green",
		value: 5,
	},
	UNPAID: {
		text: "Unpaid",
		color: "red",
		value: 10,
	},
};

export const TranslationData = [
	{ label: "EN", value: "" },
	{ label: "MY", value: "_my" },
	{ label: "ZH", value: "_zh" },
	{ label: "VN", value: "_vn" },
];

export const StatusData = [
	{ label: "Active", value: true },
	{ label: "Inactive", value: false },
];

export const SortingType = {
	MENUS: "menus",
	CATEGORIES: "categories",
	MENU_OPTIONS: "menu_options",
	MENU_OPTION_VALUES: "menu_option_values",
	SITE_BANNERS: "site_banners",
};

export const ModifyType = {
	CREATE: "Create",
	EDIT: "Edit",
};

export const ApiLogKey = {
	API: "api",
	ACTIVITY: "activity",
};

export const ApiLogData = [
	{ label: "Api", value: ApiLogKey.API },
	{ label: "Activity", value: ApiLogKey.ACTIVITY },
];

export const REGEX = {
	EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	SPACING_REGEX: /\s/g,
	SPECIAL_CHARACTER_REGEX: /[^A-Za-z0-9-]/g,
	EMOJI_REGEX: /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
};

export const DAYS = ["Monday", "Tuesday", "Wednesday", "Thurday", "Friday", "Saturday", "Sunday"];

export const CustomInputType = {
	TEXT_INPUT: "TextInput",
	SELECT_INPUT: "SelectInput",
};
