import { Drawer, FileInput, Grid, Group, LoadingOverlay, NumberInput, Radio, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import SubmitButton from "../../components/common/SubmitButton";
import supabase from "../../utils/Supabase";
import dayjs from "dayjs";
import { DateInput } from "@mantine/dates";

export default ({ opened, onClose, pressDetail, successCallback }) => {
	const matches = useMediaQuery("(max-width: 1023px)");

	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			title: "",
			date: "",
			file: "",
			type: "file",
		},
		validate: {
			title: (value) => value === "" && "Title is required.",
			date: (value) => value === "" && "Date is required.",
			file: (value) => value === "" && !pressDetail && "File is required.",
		},
	});

	useEffect(() => {
		if (!!pressDetail) {
			console.log(pressDetail);
			form.setValues({
				title: pressDetail?.title,
				date: dayjs(pressDetail?.date).toDate(),
			});
		} else {
			form.reset();
		}
	}, [pressDetail]);

	const formHandler = async (values) => {
		try {
			setIsLoading(true);

			let response;

			if (!!pressDetail) {
				let path = "";

				if (values.file !== "" && values.type === "file") {
					const { data, error } = await supabase.storage.from("press-centre").upload(values.file.name, values.file, {
						cacheControl: "3600",
						upsert: false,
					});

					if (error !== null) {
						throw error.message;
					}

					path = data.path;
				}

				let objToUpdate = {
					title: values.title,
					date: dayjs(values.date).format("D MMMM YYYY"),
				};

				if (path !== "") {
					objToUpdate = {
						...objToUpdate,
						file: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/press-centre/${path}`,
					};
				}

				if (values.type === "link") {
					objToUpdate = {
						...objToUpdate,
						file: values.file,
					};
				}

				response = await supabase.from("press-centre").update(objToUpdate).eq("id", pressDetail.id);
			} else {
				let path = "";

				let objToUpdate = {
					title: values.title,
					date: dayjs(values.date).format("D MMMM YYYY"),
				};

				if (values.type === "file") {
					const { data, error } = await supabase.storage.from("press-centre").upload(values.file.name, values.file, {
						cacheControl: "3600",
						upsert: false,
					});

					if (error !== null) {
						throw error.message;
					}

					path = data.path;
					objToUpdate = {
						...objToUpdate,
						file: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/press-centre/${path}`,
					};
				} else {
					objToUpdate = {
						...objToUpdate,
						file: values.file,
					};
				}

				response = await supabase.from("press-centre").insert(objToUpdate);
			}

			if (response.error !== null) {
				throw response.error.message;
			}

			onCloseHandler();
			showNotification({
				title: "Success!",
				message: `Successfully ${!pressDetail ? "created" : "edited"}.`,
				color: "green",
			});
			successCallback();
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err.toString(),
				color: "red",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onCloseHandler = () => {
		form.reset();
		onClose();
	};

	return (
		<Drawer opened={opened} onClose={onCloseHandler} title={pressDetail ? "Edit Press" : "Create Press"} padding="xl" position="right" size={matches ? "100%" : "35vw"} closeOnClickOutside={false}>
			<form onSubmit={form.onSubmit(formHandler)} onReset={form.onReset}>
				<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
				<Stack mt={10} mb={200} justify="space-between">
					<Grid>
						<Grid.Col span={12}>
							<TextInput label="Title" placeholder="Title" withAsterisk {...form.getInputProps("title")} />
						</Grid.Col>
						<Grid.Col span={12}>
							<DateInput label="Date" placeholder="Date" {...form.getInputProps("date")} valueFormat="D MMMM YYYY" withAsterisk />
						</Grid.Col>
						<Grid.Col span={12}>
							<Radio.Group name="type" label="Type" {...form.getInputProps("type")}>
								<Group mt="xs">
									<Radio value="file" label="File" />
									<Radio value="link" label="Link" />
								</Group>
							</Radio.Group>
						</Grid.Col>
						<Grid.Col span={12}>{form.values.type === "file" ? <FileInput placeholder="Pick file" label="Press File" withAsterisk {...form.getInputProps("file")} /> : <TextInput label="Press Link" placeholder="Press Link" withAsterisk {...form.getInputProps("file")} />}</Grid.Col>
					</Grid>
					<SubmitButton />
				</Stack>
			</form>
		</Drawer>
	);
};
