import React, { useEffect, useState } from 'react';
import {
    ActionIcon,
    Button,
    createStyles,
    Divider,
    Grid,
    Group,
    Image,
    LoadingOverlay,
    ScrollArea,
    Stack,
    Switch,
    Text,
    Textarea,
    TextInput,
    useMantineTheme,
} from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { IconCheck, IconTrash, IconX } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { DateTimePicker, YearPickerInput } from '@mantine/dates';
import dayjs from 'dayjs';

import supabase from '../../../../../utils/Supabase';

import { ImageUpload, FormFooter } from '../../../../../components/common';
import { statusHandler } from '../../../../../utils/Utils';

const useStyles = createStyles((theme) => ({
    root: {
        minWidth: '150px',
        marginRight: 10,
    },
    form: {},
    footer: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        zIndex: 10,
        width: '-webkit-fill-available',
        backgroundColor: 'white',
    },
    footer_content: {
        paddingBottom: 10,
        width: '100%',
    },
    right_btn: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 10,
        marginTop: 10,
    },
}));

function LineupsForm({ details }) {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    const [isLoading, toggleLoading] = useToggle();
    const [lineups, setLineups] = useState([]);

    const form = useForm({
        initialValues: {
            name: '',
            desc: '',
            image: null,
        },
        validate: {
            name: (value) => value === '' && 'Name is required.',
            desc: (value) => value === '' && 'Description is required.',
            image: (value) => (value === null || value.length === 0) && 'Image is required.',
        },
    });

    useEffect(() => {
        if (details) {
            getLineUps();
        }
    }, [details]);

    const getLineUps = async () => {
        try {
            toggleLoading();

            const { data, error } = await supabase
                .from('line-ups')
                .select('*')
                .eq('gtlf_id', details?.id);

            if (error !== null) {
                throw error.message;
            }

            console.log(data);

            setLineups(data);
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err.toString(),
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    const formHandler = async (values) => {
        try {
            toggleLoading();

            let datetime = dayjs().valueOf();

            let year = dayjs(details.year).format('YYYY');
            let imgPath = `${year}/lineups/${datetime}.png`;
            let { file } = values.image[0];

            const { data, error } = await supabase.storage.from('gtlf').upload(imgPath, file, {
                cacheControl: '3600',
                upsert: false,
            });

            if (error !== null) {
                throw error.message;
            }

            let response = await supabase.from('line-ups').insert({
                gtlf_id: details?.id,
                name: values.name,
                image: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/gtlf/${data.path}`,
                desc: values.desc,
            });

            if (response.error !== null) {
                throw response.error.message;
            }

            showNotification({
                title: 'Success!',
                message: `Successfully added line up.`,
                color: 'green',
            });

            getLineUps();
            form.reset();
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err.toString(),
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    const deleteLineUp = async (item) => {
        toggleLoading();
        try {
            const url = item.image.split('/').splice(-3);

            const { error: deleteError } = await supabase.storage
                .from('gtlf')
                .remove([url.join('/')]);

            if (deleteError !== null) {
                throw error.message;
            }

            const { error } = await supabase.from('line-ups').delete().eq('id', item.id);

            if (error !== null) {
                throw error.message;
            }

            showNotification({
                title: 'Success!',
                message: `Successfully deleted ${item.name}`,
                color: 'green',
            });

            getLineUps();
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    return (
        <Stack style={{ marginBottom: 70, marginTop: 10 }}>
            <LoadingOverlay visible={isLoading} overlayBlur={0.5} />
            <form onSubmit={form.onSubmit(formHandler)} onReset={form.reset}>
                <Stack spacing={0}>
                    <Grid>
                        <Grid.Col lg={3}>
                            <ImageUpload
                                maxNumber={1}
                                title={'Image'}
                                withAsterisk
                                imgWidth={200}
                                {...form.getInputProps(`image`)}
                            />
                        </Grid.Col>
                        <Grid.Col lg={9}>
                            <Stack>
                                <TextInput
                                    label="Name"
                                    withAsterisk
                                    {...form.getInputProps('name')}
                                />
                                <Textarea
                                    label="Description"
                                    minRows={5}
                                    withAsterisk
                                    {...form.getInputProps('desc')}
                                />
                            </Stack>
                        </Grid.Col>
                    </Grid>
                    <div className={classes.right_btn}>
                        <Button type="submit" color="teal" classNames={{ root: classes.root }}>
                            Add Line Up
                        </Button>
                    </div>
                </Stack>
                <Divider pt={13} />
                <ScrollArea type="never">
                    {lineups.map((item, index) => (
                        <>
                            {index !== 0 && <Divider mt={13} pt={13} />}
                            <Group>
                                <Image
                                    width={50}
                                    src={item.image}
                                    alt="Line Up Image"
                                    styles={{
                                        image: { aspectRatio: '1 !important', borderRadius: 50 },
                                    }}
                                />
                                <Stack spacing={0} style={{ flex: 1 }}>
                                    <Text fw={700} lineClamp={1}>
                                        {item.name}
                                    </Text>
                                    <Text fs="italic" lineClamp={1}>
                                        {item.desc}
                                    </Text>
                                </Stack>
                                <ActionIcon
                                    color="red"
                                    variant="light"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        statusHandler({
                                            id: item,
                                            name: item?.name,
                                            onConfirm: deleteLineUp,
                                        });
                                    }}>
                                    <IconTrash size={16} />
                                </ActionIcon>
                            </Group>
                        </>
                    ))}
                </ScrollArea>
            </form>
        </Stack>
    );
}

export default LineupsForm;
