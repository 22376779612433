import { Stack, Text } from '@mantine/core';

/// <summary>
/// Author: KrisuKodes
/// </summary>
export default ({ label, value }) => (
    <Stack spacing={8}>
        <Text size="sm" weight="500" mt={2}>
            {label}
        </Text>
        <Text size="sm">{value}</Text>
    </Stack>
);
