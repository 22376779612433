import React from "react";
import { Stack, Text } from "@mantine/core";
import { IconPackage } from "@tabler/icons-react";

export default function TableEmptyState({ title = "No data found", colSpan = 5 }) {
	return (
		<tr>
			<td colSpan={colSpan}>
				<Stack spacing={0} align={"center"}>
					<IconPackage size={100} stroke={1} />
					<Text weight={500}>{title}</Text>
				</Stack>
			</td>
		</tr>
	);
}
