import React, { useEffect, useRef, useState } from 'react';
import { createStyles, Group, Image, ActionIcon, UnstyledButton, Text, Stack } from '@mantine/core';
import ImageUploading from 'react-images-uploading';
import { IconPlus, IconX } from '@tabler/icons-react';
import Cropper from 'react-cropper';

import { statusHandler } from '../../utils/Utils';

import 'cropperjs/dist/cropper.css';
import { openConfirmModal } from '@mantine/modals';

const useStyles = createStyles((_params) => ({
    multipleImage: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ced4da',
        height: '100px',
        width: '100px',
        borderRadius: 5,
    },
    imageItem: {
        position: 'relative',
        borderRadius: 5,

        '&:hover button': {
            display: 'block',
        },
    },
    removeBtn: {
        display: 'none',
        height: 20,
        width: 20,
        background: 'black',
        borderRadius: 999,
        position: 'absolute',
        zIndex: 10,
        top: 3,
        right: 2,
    },
    imageLabel: {
        fontWeight: '500',
        paddingBottom: 5,
        fontSize: 14,
    },
    errorLabel: {
        paddingTop: 5,
        color: 'red',
        fontSize: 12,
    },
    redBorder: {
        border: '1px solid red',
    },
}));

const ImageUpload = ({
    maxNumber = 1,
    title,
    withAsterisk = false,
    imagesToDelete,
    form,
    aspectRatio = 1,
    imgWidth = 100,
    ...props
}) => {
    const { classes, cx } = useStyles();
    const cropperRef = useRef(null);
    const [currImgLength, setCurrImgLength] = useState(0);

    useEffect(() => {
        setCurrImgLength(props.value?.length ?? 0);
    }, [props.value]);

    const onChange = (imageList) => {
        if (imageList.length > 0 && imageList.length > currImgLength) {
            openConfirmModal({
                title: 'Crop Image',
                centered: true,
                children: (
                    <Cropper
                        src={imageList[imageList.length - 1].data_url}
                        style={{ height: 400, width: '100%' }}
                        initialAspectRatio={aspectRatio}
                        guides={false}
                        ref={cropperRef}
                        cropBoxResizable={false}
                        autoCropArea={1}
                        viewMode={2}
                    />
                ),
                labels: { confirm: 'Crop', cancel: 'Cancel' },
                confirmProps: { color: 'blue' },
                zIndex: 999,
                onConfirm: async () => {
                    const imageElement = cropperRef?.current;
                    const cropper = imageElement?.cropper;
                    let dataUrl = cropper.getCroppedCanvas().toDataURL();

                    cropper.getCroppedCanvas().toBlob((blob) => {
                        let newImageList = [...imageList];

                        newImageList[imageList.length - 1] = {
                            data_url: dataUrl,
                            file: blob,
                        };

                        props.onChange(newImageList);
                    });
                },
            });

            return;
        }

        props.onChange(imageList);
        setCurrImgLength(imageList.length);
    };

    const removeConfirmation = (id, index, onImageRemove) => {
        statusHandler({
            id: id,
            name: 'this image',
            onConfirm: () => {
                if (form !== undefined && !imagesToDelete?.find((x) => x === id)) {
                    form.setFieldValue('remove_imgs', [...imagesToDelete, id]);
                }

                onImageRemove(index);
            },
        });
    };

    const removeImageHandler = (id, index, onImageRemove) => {
        if (form !== undefined && !imagesToDelete?.find((x) => x === id)) {
            form.setFieldValue('remove_imgs', [...imagesToDelete, id]);
        }

        onImageRemove(index);
    };

    return (
        <Stack spacing={0}>
            {title && (
                <Group spacing={4}>
                    <Text className={classes.imageLabel}>{title}</Text>
                    {withAsterisk && (
                        <Text pb={4} weight={'bold'} color={'red'} size={12}>
                            *
                        </Text>
                    )}
                </Group>
            )}
            <Group spacing={10}>
                <ImageUploading
                    multiple
                    maxNumber={maxNumber}
                    dataURLKey={'data_url'}
                    acceptType={['jpg', 'jpeg', 'png', 'svg']}
                    {...props}
                    onChange={onChange}>
                    {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
                        <>
                            {imageList.map((image, index) => (
                                <div key={index} className={classes.imageItem}>
                                    <UnstyledButton
                                        className={classes.removeBtn}
                                        onClick={() => {
                                            if (imagesToDelete !== undefined) {
                                                removeConfirmation(image.id, index, onImageRemove);
                                            } else {
                                                removeImageHandler(image.id, index, onImageRemove);
                                            }
                                        }}>
                                        <IconX size={20} color={'white'} stroke={4.5} />
                                    </UnstyledButton>
                                    <Image
                                        width={imgWidth}
                                        height={aspectRatio === 1 ? imgWidth : 100}
                                        radius={5}
                                        fit="contain"
                                        src={image.data_url}
                                    />
                                </div>
                            ))}
                            {imageList.length < maxNumber && (
                                <ActionIcon
                                    className={cx(classes.multipleImage, {
                                        [classes.redBorder]: props?.error,
                                    })}
                                    style={{
                                        width: imgWidth,
                                        height: aspectRatio === 1 ? imgWidth : 100,
                                    }}
                                    onClick={onImageUpload}
                                    {...dragProps}>
                                    <IconPlus size={16} />
                                    <Text size={12}>Add Photo</Text>
                                </ActionIcon>
                            )}
                        </>
                    )}
                </ImageUploading>
            </Group>
            {props?.error !== undefined && (
                <Text className={classes.errorLabel}>{props?.error}</Text>
            )}
        </Stack>
    );
};

export default React.memo(ImageUpload);
