import { Drawer, Grid, LoadingOverlay, NumberInput, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { ImageUpload } from "../../components/common";
import SubmitButton from "../../components/common/SubmitButton";
import { StatusData, TranslationData } from "../../constants";
import supabase from "../../utils/Supabase";
import dayjs from "dayjs";

export default ({ opened, onClose, bannerDetail, successCallback }) => {
	const matches = useMediaQuery("(max-width: 1023px)");

	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			index: 0,
			active: false,
			media: [],
		},
		validate: {
			index: (value) => value === "" && "Index is required.",
			media: (value) => value.length === 0 && !bannerDetail && "Image is required.",
		},
	});

	useEffect(() => {
		if (!!bannerDetail) {
			console.log(bannerDetail);
			form.setValues({
				index: bannerDetail?.index,
				active: bannerDetail?.active,
			});
		} else {
			form.reset();
		}
	}, [bannerDetail]);

	const formHandler = async (values) => {
		try {
			setIsLoading(true);

			let response;

			if (!!bannerDetail) {
				response = await supabase.from("banners").update({ index: values.index, active: values.active }).eq("id", bannerDetail.id);
			} else {
				let datetime = dayjs().valueOf();
				let { file } = values.media[0];

				const { data, error } = await supabase.storage.from("banners").upload(`${datetime}.png`, file, {
					cacheControl: "3600",
					upsert: false,
				});

				if (error !== null) {
					throw error.message;
				}

				response = await supabase.from("banners").insert({
					image: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/banners/${data.path}`,
					active: values.active,
					index: values.index,
				});
			}

			if (response.error !== null) {
				throw response.error.message;
			}

			onCloseHandler();
			showNotification({
				title: "Success!",
				message: `Successfully ${!bannerDetail ? "created" : "edited"}.`,
				color: "green",
			});
			successCallback();
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err.toString(),
				color: "red",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onCloseHandler = () => {
		form.reset();
		onClose();
	};

	return (
		<Drawer opened={opened} onClose={onCloseHandler} title={bannerDetail ? "Edit Banner" : "Create Banner"} padding="xl" position="right" size={matches ? "100%" : "35vw"} closeOnClickOutside={false}>
			<form onSubmit={form.onSubmit(formHandler)} onReset={form.onReset}>
				<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
				<Stack mt={10} justify="space-between">
					<Grid>
						{!bannerDetail && (
							<Grid.Col span={12}>
								<ImageUpload maxNumber={1} title={"Media"} withAsterisk form={form} imagesToDelete={form.values.remove_imgs} imgWidth={250} aspectRatio={2.5 / 1} {...form.getInputProps(`media`)} />
							</Grid.Col>
						)}

						<Grid.Col span={6}>
							<NumberInput label="Index" placeholder="Index" withAsterisk {...form.getInputProps("index")} min={0} />
						</Grid.Col>
						<Grid.Col span={6}>
							<Select label="Status" placeholder="Status" data={StatusData} {...form.getInputProps("active")} />
						</Grid.Col>
					</Grid>
					<SubmitButton />
				</Stack>
			</form>
		</Drawer>
	);
};
