import {
    Badge,
    Button,
    Card,
    createStyles,
    LoadingOverlay,
    ScrollArea,
    Stack,
    Table,
} from '@mantine/core';
import { useMediaQuery, useToggle } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { showNotification } from '@mantine/notifications';
import TableEmptyState from '../../components/common/TableEmptyState';
import supabase from '../../utils/Supabase';
import { useNavigate } from 'react-router-dom';
import { WebRoutes } from '../../constants';

const useStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.fn.lighten('#2e3b61', 0.1),

        '&:hover': {
            backgroundColor: theme.fn.darken('#2e3b61', 0),
        },

        '&:disabled': {
            backgroundColor: '#2e3b61',
            color: 'white',
        },
    },
    container: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
    item: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        cursor: 'pointer',
    },
    dragHandle: {
        ...theme.fn.focusStyles(),
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    },
    itemDragging: {
        boxShadow: theme.shadows.sm,
    },
}));

/// <summary>
/// Author: KurisuKodo
/// </summary>
function Gtlf() {
    const { classes, cx } = useStyles();
    const matches = useMediaQuery('(max-width: 1023px)');
    const navigate = useNavigate();

    const [gtlfs, setGtlfs] = useState([]);
    const [isLoading, toggleLoading] = useToggle();

    useEffect(() => {
        getGtlfs();
    }, []);

    const getGtlfs = async () => {
        toggleLoading();
        try {
            const { data, error } = await supabase
                .from('gtlf')
                .select('*')
                .order('year', { ascending: false });

            if (error !== null) {
                throw error.message;
            }

            setGtlfs(data);
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err,
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    const createHandler = () => {
        navigate(WebRoutes.GTLF_CREATE);
    };

    const editHandler = (value) => {
        navigate(
            WebRoutes.GTLF_DETAILS.replace(':id', value?.id) +
                `?year=${value?.year}&title=${value?.title}`
        );
    };

    const items = gtlfs?.map((value, index) => (
        <tr className={cx(classes.item)} onClick={() => editHandler(value)}>
            <td>{value?.year}</td>
            <td>{value?.title}</td>
            <td>{value?.alias}</td>
            <td>
                {value?.status ? (
                    <Badge color="teal" radius="sm" variant="filled">
                        Active
                    </Badge>
                ) : (
                    <Badge color="red" radius="sm" variant="filled">
                        Inactive
                    </Badge>
                )}
            </td>
        </tr>
    ));

    return (
        <Layout
            title="GTLF"
            createBtn={
                <Button
                    onClick={createHandler}
                    color="teal"
                    variant="filled"
                    compact
                    leftIcon={<IconPlus size="16" />}>
                    New
                </Button>
            }>
            <Stack
                spacing={0}
                style={{ height: matches ? '100%' : 'auto', maxHeight: matches ? '100%' : '90%' }}
                justify="space-between">
                <Stack spacing={0} style={{ height: '95%' }}>
                    <ScrollArea mt={10}>
                        <Card p="xs" withBorder>
                            <LoadingOverlay visible={isLoading} overlayBlur={0.5} />
                            <Table highlightOnHover style={{ borderRadius: 10 }}>
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Title</th>
                                        <th>Alias</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gtlfs.length > 0 ? items : <TableEmptyState colSpan={5} />}
                                </tbody>
                            </Table>
                        </Card>
                    </ScrollArea>
                </Stack>
            </Stack>
        </Layout>
    );
}

export default Gtlf;
