import { useMediaQuery } from "@mantine/hooks";

import MobileLayout from "./mobile-layout";
import DesktopLayout from "./desktop-layout";

/// <summary>
/// Author: KrisuKodes
/// </summary>
export default function Layout({ title, children, ...rest }) {
	const matches = useMediaQuery("(max-width: 1023px)");

	// if (matches) {
	// 	return (
	// 		<MobileLayout title={title} {...rest}>
	// 			{children}
	// 		</MobileLayout>
	// 	);
	// }

	return (
		<DesktopLayout title={title} {...rest}>
			{children}
		</DesktopLayout>
	);
}
