import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { WebRoutes } from "./constants/WebRoutes";

/// <summary>
/// Author: JavaChips
/// </summary>
function App() {
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "GTLF Admin";

		navigate(WebRoutes.GTLF);
	}, []);

	return null;
}

export default App;
