import React, { useEffect, useState } from 'react';
import { ActionIcon, LoadingOverlay, Tabs } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Layout from '../../../components/layout';

import DetailsForm from './components/details-form';
import supabase from '../../../utils/Supabase';
import { showNotification } from '@mantine/notifications';
import { useToggle } from '@mantine/hooks';
import LineupsForm from './components/lineups-form';
import HappeningForm from './components/happening-form';
import ProgrammeForm from './components/programme-form';
import CuratorialTeam from './components/curatorial-form';

const GTLF_TABS = {
    DETAILS: 'details',
    CURATORIAL_TEAM: 'curatorialTeam',
    LINE_UPS: 'lineUps',
    FESTIVAL_PROGRAMME: 'festivalProgramme',
    HAPPENING_SIMULTANEOUSLY: 'happeningSimultaneously',
};

function GtlfDetails() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [details, setDetails] = useState(null);
    const [isLoading, toggleLoading] = useToggle();

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        try {
            toggleLoading();

            const { data, error } = await supabase
                .from('gtlf')
                .select('*')
                .eq('id', id)
                .limit(1)
                .single();

            if (error !== null) {
                throw error.message;
            }

            let newData = {
                ...data,
                year: dayjs(data?.year).toDate(),
                image: [{ data_url: data?.image }],
                start_date: dayjs(data?.start_date).toDate(),
                end_date: dayjs(data?.end_date).toDate(),
            };

            setDetails(newData);
        } catch (err) {
            showNotification({
                title: 'Oops',
                message: err.toString(),
                color: 'red',
            });
        } finally {
            toggleLoading();
        }
    };

    return (
        <Layout
            title={`Edit ${details?.year ? dayjs(details?.year).format('YYYY') : ''} - ${
                details?.title || ''
            }`}
            backBtn={
                <ActionIcon onClick={() => navigate(-1)} color="gray" variant="filled" compact>
                    <IconChevronLeft size="16" />
                </ActionIcon>
            }>
            <Tabs defaultValue={GTLF_TABS.DETAILS}>
                <Tabs.List>
                    <Tabs.Tab value={GTLF_TABS.DETAILS}>Details</Tabs.Tab>
                    <Tabs.Tab value={GTLF_TABS.CURATORIAL_TEAM}>Curatorial Team</Tabs.Tab>
                    <Tabs.Tab value={GTLF_TABS.LINE_UPS}>Line Ups</Tabs.Tab>
                    <Tabs.Tab value={GTLF_TABS.FESTIVAL_PROGRAMME}>Festival Programme</Tabs.Tab>
                    <Tabs.Tab value={GTLF_TABS.HAPPENING_SIMULTANEOUSLY}>
                        Happening Simultaneously
                    </Tabs.Tab>
                </Tabs.List>

                <LoadingOverlay visible={isLoading} overlayBlur={0.5} />

                {details && (
                    <>
                        <Tabs.Panel value={GTLF_TABS.DETAILS}>
                            <DetailsForm details={details} />
                        </Tabs.Panel>
                        <Tabs.Panel value={GTLF_TABS.CURATORIAL_TEAM}>
                            <CuratorialTeam details={details} />
                        </Tabs.Panel>
                        <Tabs.Panel value={GTLF_TABS.LINE_UPS}>
                            <LineupsForm details={details} />
                        </Tabs.Panel>
                        <Tabs.Panel value={GTLF_TABS.FESTIVAL_PROGRAMME}>
                            <ProgrammeForm details={details} />
                        </Tabs.Panel>
                        <Tabs.Panel value={GTLF_TABS.HAPPENING_SIMULTANEOUSLY}>
                            <HappeningForm details={details} />
                        </Tabs.Panel>
                    </>
                )}
            </Tabs>
        </Layout>
    );
}

export default GtlfDetails;
