import { Navbar } from "@mantine/core";

import Brand from "./_brand";
import Links from "./_links";
import User from "./_user";

/// <summary>
/// Author: KrisuKodes
/// </summary>
export default function NavBar() {
	return (
		<Navbar width={{ base: 230 }} p="xs" style={{ backgroundColor: "#fffaf4" }}>
			<Navbar.Section>
				<Brand />
			</Navbar.Section>
			<Navbar.Section grow>
				<Links />
			</Navbar.Section>
			<Navbar.Section>
				<User />
			</Navbar.Section>
		</Navbar>
	);
}
