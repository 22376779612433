export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

export const ApiRoutes = {
    LOGIN: `${API_URL}/sanctum/token`,
    ORDERS: `${API_URL}/portal/orders`,
    SALES_REPORT: `${API_URL}/portal/financial/sales`,
    DOWNLOAD_SALES_REPORT: `${API_URL}/portal/financial/sales/download`,

    MERCHANT_CATEGORIES: `${API_URL}/portal/settings/merchant-category`,
    MERCHANT_CATEGORIES_SORT: `${API_URL}/portal/settings/merchant-categories-sort`,
    MERCHANTS: `${API_URL}/portal/merchants`,
    REGISTER_MERCHANT: `${API_URL}/portal/merchants/register/merchant`,

    CUSTOMERS: `${API_URL}/portal/customers`,
    MENUS: `${API_URL}/portal/menus`,
    MENU_OPTION: `${API_URL}/portal/menus/:menuId/menu-options`,
    MENU_OPTION_VALUES: `${API_URL}/portal/menu-options`,
    LINK_MENU_OPTION: `${API_URL}/portal/menus/:menuId/link-options`,
    MASTER_MENU_OPTIONS: `${API_URL}/portal/menus/:menuId/master-menu-options`,
    SORT: `${API_URL}/portal/sort`,
    MENU_CATEGORY: `${API_URL}/portal/menu-categories`,
    API_LOG: `${API_URL}/portal/system/logs`,
    MERCHANT_PROFILE: `${API_URL}/portal/profile/merchant`,

    OPERATING_HOURS: `${API_URL}/portal/profile/operating-hours`,
    CHANGE_PASSWORD: `${API_URL}/portal/profile/change-password`,

    MERCHANT_PAYMENT_CHANNELS: `${API_URL}/portal/merchants/:Id/payment-channels`,

    BANNERS: `${API_URL}/portal/system/site-banners`,
    USERS: `${API_URL}/portal/users`,
    USERS_DETAIL: `${API_URL}/portal/users/detail`,

    ASSIGNABLE_ROLES: `${API_URL}/portal/users/assignable-roles`,

    SETTLEMENTS: `${API_URL}/portal/financial/settlement`,
    SETTLEMENT_BATCH: `${API_URL}/portal/financial/settlement-batch`,

    GET_BANKS: `${API_URL}/portal/settings/settlement-banks`,
    SETTLEMENT_BANK: `${API_URL}/portal/merchants/:id/settlement-bank`,
};
