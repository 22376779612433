import React from 'react';
import { Button, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.fn.lighten('#2e3b61', 0.1),

        '&:hover': {
            backgroundColor: theme.fn.darken('#2e3b61', 0),
        },

        '&:disabled': {
            backgroundColor: '#2e3b61',
            color: 'white',
        },
    },
}));

export default ({ text = 'Save', ...rest }) => {
    const { classes } = useStyles();

    return (
        <Button type="submit" mt={25} fullWidth classNames={{ root: classes.root }} {...rest}>
            {text}
        </Button>
    );
};
