import { Drawer, Grid, LoadingOverlay, NumberInput, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { ImageUpload } from "../../components/common";
import SubmitButton from "../../components/common/SubmitButton";
import { StatusData, TranslationData } from "../../constants";
import supabase from "../../utils/Supabase";
import dayjs from "dayjs";

export default ({ opened, onClose, muaraDetail, successCallback }) => {
	const matches = useMediaQuery("(max-width: 1023px)");

	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			image: [],
			title: "",
			link: "",
		},
		validate: {
			image: (value) => value.length === 0 && !muaraDetail && "Image is required.",
			title: (value) => value === "" && "Title is required.",
			link: (value) => value === "" && "Link is required.",
		},
	});

	useEffect(() => {
		if (!!muaraDetail) {
			console.log(muaraDetail);
			form.setValues({
				title: muaraDetail?.title,
				link: muaraDetail?.link,
			});
		} else {
			form.reset();
		}
	}, [muaraDetail]);

	const formHandler = async (values) => {
		try {
			setIsLoading(true);

			let response;

			console.log(values);

			if (!!muaraDetail) {
				response = await supabase.from("muara").update(values).eq("id", muaraDetail.id);
			} else {
				let datetime = dayjs().valueOf();
				let { file } = values.image[0];

				const { data, error } = await supabase.storage.from("muara").upload(`${datetime}.png`, file, {
					cacheControl: "3600",
					upsert: false,
				});

				if (error !== null) {
					throw error.message;
				}

				response = await supabase.from("muara").insert({
					image: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/muara/${data.path}`,
					title: values.title,
					link: values.link,
				});
			}

			if (response.error !== null) {
				throw response.error.message;
			}

			onCloseHandler();
			showNotification({
				title: "Success!",
				message: `Successfully ${!muaraDetail ? "created" : "edited"}.`,
				color: "green",
			});
			successCallback();
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err.toString(),
				color: "red",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onCloseHandler = () => {
		form.reset();
		onClose();
	};

	return (
		<Drawer opened={opened} onClose={onCloseHandler} title={muaraDetail ? "Edit Muara" : "Create Muara"} padding="xl" position="right" size={matches ? "100%" : "35vw"} closeOnClickOutside={false}>
			<form onSubmit={form.onSubmit(formHandler)} onReset={form.onReset}>
				<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
				<Stack mt={10} justify="space-between">
					<Grid>
						{!muaraDetail && (
							<Grid.Col span={12}>
								<ImageUpload maxNumber={1} title={"Image"} withAsterisk form={form} imagesToDelete={form.values.remove_imgs} imgWidth={100} aspectRatio={1} {...form.getInputProps(`image`)} />
							</Grid.Col>
						)}
						<Grid.Col span={12}>
							<TextInput label="Title" placeholder="Title" withAsterisk {...form.getInputProps("title")} />
						</Grid.Col>
						<Grid.Col span={12}>
							<TextInput label="Link" placeholder="Link" withAsterisk {...form.getInputProps("link")} />
						</Grid.Col>
					</Grid>
					<SubmitButton />
				</Stack>
			</form>
		</Drawer>
	);
};
