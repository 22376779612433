import { Button, createStyles, Divider, Stack } from '@mantine/core';
import React from 'react';

const useStyles = createStyles((theme) => ({
    root: {
        minWidth: '150px',
        marginRight: 10,
    },
    form: {},
    footer: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        zIndex: 10,
        width: '-webkit-fill-available',
        backgroundColor: 'white',
    },
    footer_content: {
        paddingBottom: 10,
        width: '100%',
    },
    right_btn: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 3,
    },
}));

/// <summary>
/// Author: KrisuKodes
/// </summary>
const FormFooter = ({ text = 'Save' }) => {
    const { classes } = useStyles();

    return (
        <div className={classes.footer}>
            <div className={classes.footer_content}>
                <Stack spacing={0}>
                    <Divider pt={13} />
                    <div className={classes.right_btn}>
                        <Button type="submit" color="teal" classNames={{ root: classes.root }}>
                            {text}
                        </Button>
                    </div>
                </Stack>
            </div>
        </div>
    );
};

export default FormFooter;
