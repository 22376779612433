import { ActionIcon, Box, Button, Card, createStyles, Divider, Group, Image, LoadingOverlay, Modal, ScrollArea, Stack, Table, Text } from "@mantine/core";
import { useMediaQuery, useToggle } from "@mantine/hooks";
import { IconPencil, IconPlus, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { showNotification } from "@mantine/notifications";
import { statusHandler } from "../../utils/Utils";
// import CreateOrUpdateDrawer from "./CreateOrUpdateDrawer";
import TableEmptyState from "../../components/common/TableEmptyState";
import supabase from "../../utils/Supabase";
import { useEditor } from "@tiptap/react";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import { ImageUpload } from "../../components/common";

const useStyles = createStyles((theme) => ({
	root: {
		backgroundColor: theme.fn.lighten("#2e3b61", 0.1),

		"&:hover": {
			backgroundColor: theme.fn.darken("#2e3b61", 0),
		},

		"&:disabled": {
			backgroundColor: "#2e3b61",
			color: "white",
		},
	},
	container: {
		height: "100%",
		width: "100%",
		overflow: "auto",
	},
	item: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		cursor: "pointer",
	},
	dragHandle: {
		...theme.fn.focusStyles(),
		width: 40,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[6],
	},
	itemDragging: {
		boxShadow: theme.shadows.sm,
	},
}));

function MuaraWritingPrize() {
	const { classes, cx } = useStyles();
	const matches = useMediaQuery("(max-width: 1023px)");

	const [gtlfs, setGtlfs] = useState([]);

	const [isLoading, toggleLoading] = useToggle();
	const [isVisible, toggleVisible] = useToggle();
	const [sections, setSections] = useState([]);

	const [showDrawer, toggleShowDrawer] = useToggle();
	const [gtlfDetail, setGtlfDetail] = useState(null);
	const [test, setTest] = useState(null);

	const [editSection, setEditSection] = useState(null);

	const editor = useEditor({
		extensions: [StarterKit, TextStyle, Color, Link, TextAlign.configure({ types: ["heading", "paragraph"] })],
	});

	useEffect(() => {
		if (editSection !== null) {
			toggleVisible();
		}
	}, [editSection]);

	useEffect(() => {
		console.log(editor);
		if (editor !== null) {
			getGtlfs();
		}
	}, [editor]);

	const getGtlfs = async () => {
		toggleLoading();
		try {
			const { data, error } = await supabase.from("muara-writing-competition").select(
				`
              content,
              muara-writing-competition-sections(
                image,
                content
              )
            `
			);

			if (error !== null) {
				console.log(error);
				throw error.message;
			}

			if (data.length === 0) {
				return;
			}

			console.log(data);

			let existingSections = [];

			editor.commands.setContent(data[0].content);

			data[0]?.["muara-writing-competition-sections"]?.map((item) => {
				existingSections.push({
					image: item.image,
					content: item.content,
				});
			});

			setSections(existingSections);
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err,
				color: "red",
			});
		} finally {
			toggleLoading();
		}
	};

	const editSectionHandler = (item) => {
		setEditSection(item);
	};

	const deleteSection = async (index) => {
		let newArr = [...sections];

		newArr.splice(index, 1);

		setSections(newArr);
	};

	const saveHandler = async () => {
		setTest(editor.getHTML());
		toggleLoading();
		try {
			let isExistResponse = await supabase.from("muara-writing-competition").select("*").limit(1).maybeSingle();

			console.log(isExistResponse);

			if (isExistResponse.error !== null) {
				throw isExistResponse.error.message;
			}

			if (isExistResponse.data !== null) {
				await supabase.from("muara-writing-competition-sections").delete().eq("muara_writing_competition_id", isExistResponse.data.id);
				await supabase.from("muara-writing-competition").delete().eq("id", isExistResponse.data.id);
				await supabase.storage.emptyBucket("muara-writing-competition");
			}

			let MuaraWritingPrizeResponse = await supabase
				.from("muara-writing-competition")
				.insert({
					content: editor.getHTML(),
				})
				.select()
				.limit(1)
				.single();

			if (MuaraWritingPrizeResponse.error !== null) {
				throw MuaraWritingPrizeResponse.error.message;
			}

			sections.map(async (section, index) => {
				const { data, error } = await supabase.storage.from("muara-writing-competition").upload(`section-img-${index + 1}.png`, section.image.file, {
					cacheControl: "3600",
					upsert: false,
				});

				if (error !== null) {
					throw error.message;
				}

				let sectionResponse = await supabase.from("muara-writing-competition-sections").insert({
					muara_writing_competition_id: MuaraWritingPrizeResponse.data.id,
					content: section.content,
					image: `https://fnojqkroosmgddzoaywd.supabase.co/storage/v1/object/public/muara-writing-competition/${data.path}`,
				});

				if (sectionResponse.error !== null) {
					throw sectionResponse.error.message;
				}
			});

			showNotification({
				title: "Success!",
				message: "Succesfully saved muara-writing-competition details.",
				color: "green",
			});
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err.toString(),
				color: "red",
			});
		} finally {
			toggleLoading();
		}
	};

	return (
		<Layout
			title="Muara Writing Prize"
			createBtn={
				<Button onClick={saveHandler} color="teal" variant="filled" compact>
					Save
				</Button>
			}
		>
			<Stack spacing={0} style={{ height: matches ? "100%" : "auto", maxHeight: matches ? "100%" : "90%" }} justify="space-between">
				<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
				<RichTextEditor editor={editor} style={{ minHeight: 300 }}>
					<RichTextEditor.Toolbar sticky stickyOffset={60}>
						<RichTextEditor.ControlsGroup>
							<RichTextEditor.ColorPicker colors={["#25262b", "#868e96", "#fa5252", "#e64980", "#be4bdb", "#7950f2", "#4c6ef5", "#228be6", "#15aabf", "#12b886", "#40c057", "#82c91e", "#fab005", "#fd7e14"]} />
							<RichTextEditor.Link />
						</RichTextEditor.ControlsGroup>

						<RichTextEditor.ControlsGroup>
							<RichTextEditor.H1 />
							<RichTextEditor.H2 />
							<RichTextEditor.H3 />
							<RichTextEditor.H4 />
							<RichTextEditor.H5 />
							<RichTextEditor.H6 />
							<RichTextEditor.Bold />
							<RichTextEditor.Italic />
						</RichTextEditor.ControlsGroup>

						<RichTextEditor.ControlsGroup>
							<RichTextEditor.BulletList />
							<RichTextEditor.OrderedList />
							<RichTextEditor.Hr />
						</RichTextEditor.ControlsGroup>

						<RichTextEditor.ControlsGroup>
							<RichTextEditor.AlignLeft />
							<RichTextEditor.AlignCenter />
							<RichTextEditor.AlignRight />
							<RichTextEditor.AlignJustify />
						</RichTextEditor.ControlsGroup>
					</RichTextEditor.Toolbar>

					<RichTextEditor.Content />
				</RichTextEditor>
				{sections.map((item, index) => (
					<Card mt={20} shadow="sm" padding="lg" radius="md" withBorder>
						<Group position="apart" align="start">
							<div style={{ width: 68 }}></div>
							<Image width={150} src={item.image.data_url ?? item.image} alt="Image" styles={{ image: { aspectRatio: "1/1 !important" } }} />
							<Group spacing={5}>
								<ActionIcon color="orange" size="lg" variant="filled" onClick={() => editSectionHandler({ ...item, index })}>
									<IconPencil size="16" />
								</ActionIcon>
								<ActionIcon
									color="red"
									size="lg"
									variant="filled"
									onClick={() => {
										statusHandler({
											id: "deleteSection",
											name: "this section",
											onConfirm: () => deleteSection(index),
										});
									}}
								>
									<IconTrash size="16" />
								</ActionIcon>
							</Group>
						</Group>
						<div dangerouslySetInnerHTML={{ __html: item.content }} />
					</Card>
				))}
				<Group position="center" mt={20}>
					<Button color="indigo" radius="md" onClick={toggleVisible}>
						Add Section
					</Button>
				</Group>
				<SectionModal isVisible={isVisible} toggleVisible={toggleVisible} setSections={setSections} editSection={editSection} setEditSection={setEditSection} />
			</Stack>
		</Layout>
	);
}

const SectionModal = ({ isVisible, toggleVisible, setSections, editSection, setEditSection }) => {
	const [image, setImage] = useState(null);

	const editor = useEditor({
		extensions: [StarterKit, TextStyle, Color, Link, TextAlign.configure({ types: ["heading", "paragraph"] })],
	});

	const sectionHandler = () => {
		let section = {
			image: image[0],
			content: editor.getHTML(),
		};

		if (editSection !== null) {
			setSections((prev) => {
				let newArr = [...prev];

				newArr[editSection?.index] = section;

				return newArr;
			});
		} else {
			setSections((prev) => [...prev, section]);
		}

		setImage(null);
		toggleVisible();
	};

	useEffect(() => {
		if (isVisible && editSection) {
			editor.commands.setContent(editSection?.content);
			setImage([editSection?.image]);
		}
	}, [isVisible, editSection]);

	return (
		<Modal
			opened={isVisible}
			onClose={() => {
				toggleVisible();
				setEditSection(null);
			}}
			title="Add Section"
			size="70%"
		>
			<ImageUpload maxNumber={1} title={"Image"} withAsterisk imgWidth={100} aspectRatio={1 / 1} onChange={setImage} value={image} />
			<RichTextEditor editor={editor} mt={20} style={{ minHeight: 400 }}>
				<RichTextEditor.Toolbar sticky stickyOffset={60}>
					<RichTextEditor.ControlsGroup>
						<RichTextEditor.ColorPicker colors={["#25262b", "#868e96", "#fa5252", "#e64980", "#be4bdb", "#7950f2", "#4c6ef5", "#228be6", "#15aabf", "#12b886", "#40c057", "#82c91e", "#fab005", "#fd7e14"]} />
						<RichTextEditor.Link />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.H1 />
						<RichTextEditor.H2 />
						<RichTextEditor.H3 />
						<RichTextEditor.H4 />
						<RichTextEditor.H5 />
						<RichTextEditor.H6 />
						<RichTextEditor.Bold />
						<RichTextEditor.Italic />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.BulletList />
						<RichTextEditor.OrderedList />
						<RichTextEditor.Hr />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.AlignLeft />
						<RichTextEditor.AlignCenter />
						<RichTextEditor.AlignRight />
						<RichTextEditor.AlignJustify />
					</RichTextEditor.ControlsGroup>
				</RichTextEditor.Toolbar>

				<RichTextEditor.Content />
			</RichTextEditor>
			<Divider my={20} />
			<Group position="right">
				<Box w={200}>
					<Button onClick={sectionHandler} color="teal" variant="filled" fullWidth>
						Save
					</Button>
				</Box>
			</Group>
		</Modal>
	);
};

export default MuaraWritingPrize;
