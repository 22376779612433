import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";

import App from "./App";
import "./styles/globals.css";

import { WebRoutes } from "./constants/WebRoutes";

import reportWebVitals from "./reportWebVitals";

import Login from "./pages/login";

import Gtlf from "./pages/gtlf";
import GtlfDetails from "./pages/gtlf/details";
import GtlfCreate from "./pages/gtlf/create";

import PressCentre from "./pages/press-centre";
import Muara from "./pages/muara";
import InTheNews from "./pages/in-the-news";
import Locations from "./pages/locations";
import Banners from "./pages/banners";
import Partners from "./pages/partners";
import PartnerCategories from "./pages/partner-categories";
import PastFestivals from "./pages/past-festivals";
import OpenCall from "./pages/open-call";
import MuaraWritingPrize from "./pages/muara-writing-prize";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ModalsProvider>
				<Notifications />
				<Routes>
					<Route path={"/"} element={<App />} />
					<Route path={WebRoutes.LOGIN} element={<Login />} />
					<Route path={WebRoutes.GTLF} element={<Gtlf />} />
					<Route path={WebRoutes.GTLF_CREATE} element={<GtlfCreate />} />
					<Route path={WebRoutes.GTLF_DETAILS} element={<GtlfDetails />} />
					<Route path={WebRoutes.MUARA} element={<Muara />} />
					<Route path={WebRoutes.PAST_FESTIVALS} element={<PastFestivals />} />
					<Route path={WebRoutes.IN_THE_NEWS} element={<InTheNews />} />
					<Route path={WebRoutes.PRESS_CENTRE} element={<PressCentre />} />
					<Route path={WebRoutes.LOCATIONS} element={<Locations />} />
					<Route path={WebRoutes.BANNERS} element={<Banners />} />
					<Route path={WebRoutes.PARTNERS} element={<Partners />} />
					<Route path={WebRoutes.PARTNER_CATEGORIES} element={<PartnerCategories />} />
					<Route path={WebRoutes.OPEN_CALL} element={<OpenCall />} />
					<Route path={WebRoutes.MUARA_WRITING_PRIZE} element={<MuaraWritingPrize />} />
				</Routes>
			</ModalsProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
