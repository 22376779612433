import { Drawer, FileInput, Grid, LoadingOverlay, NumberInput, Select, Stack, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import SubmitButton from "../../components/common/SubmitButton";
import supabase from "../../utils/Supabase";
import dayjs from "dayjs";
import { YearPickerInput } from "@mantine/dates";

export default ({ opened, onClose, newsDetail, successCallback }) => {
	const matches = useMediaQuery("(max-width: 1023px)");

	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			year: dayjs().toDate(),
			title: "",
			desc: "",
			link: "",
		},
		validate: {
			year: (value) => value === "" && "Year is required.",
			title: (value) => value === "" && "Title is required.",
			desc: (value) => value === "" && "Description is required.",
			link: (value) => value === "" && "Link is required.",
		},
	});

	useEffect(() => {
		if (!!newsDetail) {
			form.setValues({
				year: dayjs(newsDetail?.date).toDate(),
				title: newsDetail?.title,
				desc: newsDetail?.desc,
				link: newsDetail?.link,
			});
		} else {
			form.reset();
		}
	}, [newsDetail]);

	const formHandler = async (values) => {
		try {
			setIsLoading(true);

			let response;

			if (!!newsDetail) {
				response = await supabase
					.from("in-the-news")
					.update({
						...values,
						year: dayjs(values.year).format("YYYY"),
					})
					.eq("id", newsDetail.id);
			} else {
				response = await supabase.from("in-the-news").insert({
					...values,
					year: dayjs(values.year).format("YYYY"),
				});
			}

			if (response.error !== null) {
				throw response.error.message;
			}

			onCloseHandler();
			showNotification({
				title: "Success!",
				message: `Successfully ${!newsDetail ? "created" : "edited"} news.`,
				color: "green",
			});
			successCallback();
		} catch (err) {
			showNotification({
				title: "Oops",
				message: err.toString(),
				color: "red",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onCloseHandler = () => {
		form.reset();
		onClose();
	};

	return (
		<Drawer opened={opened} onClose={onCloseHandler} title={newsDetail ? "Edit News" : "Create News"} padding="xl" position="right" size={matches ? "100%" : "35vw"} closeOnClickOutside={false}>
			<form onSubmit={form.onSubmit(formHandler)} onReset={form.onReset}>
				<LoadingOverlay visible={isLoading} overlayBlur={0.5} />
				<Stack mt={10} mb={200} justify="space-between">
					<Grid>
						<Grid.Col span={12}>
							<YearPickerInput label="Year" placeholder="Year" {...form.getInputProps("year")} valueFormat="YYYY" withAsterisk maxDate={dayjs().toDate()} />
						</Grid.Col>
						<Grid.Col span={12}>
							<TextInput label="Publisher" placeholder="Publisher" withAsterisk {...form.getInputProps("title")} />
						</Grid.Col>
						<Grid.Col span={12}>
							<Textarea label="News Headline" placeholder="News Headline" withAsterisk {...form.getInputProps("desc")} />
						</Grid.Col>
						<Grid.Col span={12}>
							<TextInput label="Link" placeholder="Link" withAsterisk {...form.getInputProps("link")} />
						</Grid.Col>
					</Grid>
					<SubmitButton />
				</Stack>
			</form>
		</Drawer>
	);
};
