import { Text, ActionIcon, Box, useMantineTheme, Group } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import { openConfirmModal } from "@mantine/modals";

import { WebRoutes } from "../../constants";
import { useNavigate } from "react-router-dom";

/// <summary>
/// Author: KrisuKodes
/// </summary>
export default function User({ isMobile = false }) {
	const navigate = useNavigate();
	const theme = useMantineTheme();

	/// <summary>
	/// Author: KrisuKodes
	/// </summary>
	const logoutHandler = () => {
		openConfirmModal({
			title: "Hold on!",
			centered: true,
			children: <Text color="dimmed">Are you sure you would like to logout?</Text>,
			labels: { confirm: "Yes, logout", cancel: "No" },
			confirmProps: { color: "blue" },
			zIndex: 999,
			onConfirm: () => {
				localStorage.removeItem("gtlfUserData");

				navigate(WebRoutes.LOGIN, {
					replace: true,
				});
			},
		});
	};

	return (
		<Box
			sx={{
				paddingTop: theme.spacing.sm,
				marginTop: theme.spacing.xs,
				borderTop: `1px solid ${theme.colors.gray[6]}`,
			}}
		>
			<Group>
				<div style={{ flex: 1 }}>
					<Text transform="capitalize" size="sm" weight="bold" color="#9f9f9e">
						Admin
					</Text>
				</div>
				<ActionIcon variant="filled" onClick={logoutHandler} color="red">
					<IconLogout size={16} />
				</ActionIcon>
			</Group>
		</Box>
	);
}
